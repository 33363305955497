import ElementWithHoverinfo from "components/ElementWithHoverinfo";
import { faAnchorCircleCheck, faAnchor } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import ProptTypes from 'prop-types';

function DeepDiveIcon({ isDeepDive, onClick, tooltipRight, className }) {
    const { t } = useTranslation(['common'])

    return (
        <ElementWithHoverinfo
            id='deepDiveIcon'
            active
            animateOnChange={true}
            icon={isDeepDive ? faAnchorCircleCheck : faAnchor}
            onClick={!isDeepDive ? onClick : null}
            iconColor={`${isDeepDive ? 'text-primary-500' : 'text-gray-400 hover:text-primary-600'}`}
            tooltipWidth={isDeepDive ? 'min-w-[120px]' : 'min-w-[140px]'}
            tooltip={isDeepDive ? t('Go to Tabview') : t('transformTopic', 'Transforms a Topic into a Deep Dive')}
            tooltipRight={tooltipRight}
            className={className}
        />
    );
}

DeepDiveIcon.propTypes = {
    isDeepDive: ProptTypes.bool,
    onClick: ProptTypes.func,
    tooltipRight: ProptTypes.bool,
    className: ProptTypes.string
}

export default DeepDiveIcon;