import { useState } from "react";
import { useTranslation } from "react-i18next";

import CardGrid from "components/CardGrid";
import Card from "components/Card";
import FormContainer from "components/FormContainer";
import Button from "components/Button";

import GuideForm from "components/GuideForm";
import Modal from "components/Modal";

import ImpactVsImplementationSvg from '../Images/svg/ImpactVsEOI.svg';
import ImpactVsImplementationCheckedSvg from '../Images/svg/ImpactVsEOIChecked.svg';
import HeuristikEng from '../Images/svg/Heuristik_ENG.svg';
import ValuesImg from '../Images/values.png'

// TODO Translation
// TODO Translation Images
function Step5Quantify() {
    const { t } = useTranslation();
    const [guideActive, setGuideActive] = useState(false);
    const text = {
        pageTitle: t('Quantify your factors'),
        card1Title: t('The objective (1/2)'),
        card1Text: t(`A classical, proven logic consists of mapping the Ease of Implementation against the Impact`),
        card2Title: t('The objective (2/2)'),
        card2Text: t(`This will allow you to build a portfolio that maximizes impact while being achievable`),
        card3Title: t('Identify values against baseline'),
        card3Text: t(`Start with identifying potential impact values against the factor's baseline - impact always being measured against the Key Results you defined`),
        card4Title: t('Try heuristics'),
        card4Text: t(`You might want to use different types of heuristics as a pragmatic way to quantify`),
        modal1Title: t('Impact vs Ease of Implementation'),
        modal3Title: t('Values against baseline'),
    }
    const guideText = [
        {
            header: t('Why quantfiy'),
            text: t(`Check the plot below which insights you get after you will have structured and quantified the factors* that can contribute to the resolution of your problem, as specified in your Key Results. You will quickly identify which factors you should prioritize, and thus work** on. To achieve this, however, you need at least a rough quantification
            \n•	Numbers are typically more objective and less prone to misinterpretation than words
            •	Numbers allow you to “zero in” on the most critical issues
            •	They will increase clarity in your thoughts
            \n* A factor is something that impacts the desired outcome in your problem statement. You need to know and define these factors in order to solve your problem
            ** This piece of work might be called  “epic”, “workstream”, or “sub-cluster” at your workplace. It is a piece of work behind which it typically makes sense to staff a team`),
            linkText: t('Ease of implementation vs. impact →'),
            link: ImpactVsImplementationSvg,
        },
        {
            header: t('Baseline and impact from there'),
            text: t(`•	Start with your baseline numbers - these should be your starting point
            \n•	Estimate how much the factor could contribute to to the resolution of your problem, as specified in your Key Results. Use your team and experts to solidify the numbers, or use heuristics if it helps
            \n•	Estimate realistically and, perhaps, rather conservatively
            \n•	Check the aggregation of all factors to see whether you are on track to reach your target
            \n•	All factors should add up significantly above (or below) your target. If not, you will probably struggle to get your problem solved and might want to revisit your problem statement`),
        },
        {
            header: t('Heuristics'),
            text: t(`Origin: Ancient greek heurískō, “to find”
            \n•	A heuristic thus is a method “serving to find out or discover”*
            •	A problem-solving heuristic is an informal, intuitive, speculative procedure that leads to a solution in some cases but not in others**
            •	They can be highly useful to increase efficiency**
            •	There might be situations, e.g., in highly regulated environments, where the use of heuristics is not recommended
            \n* Todd, P. M., und Gigerenzer, G. “Précis of Simple Heuristics That Make Us Smart.”
            Behavioral and Brain Sciences, 2000, 23, 727–780, p. 738            
            `),
            linkText: t(`** Sternberg, Robert J. , Berlyne, D.E. und Vinacke, W. Edgar. "thought". Encyclopedia Britannica, 14 May. 2008, 
            . Retrieved November 22, 2021`),
            link: 'https://www.britannica.com/topic/thought'
        },
        {
            header: t('Example: Algorithm vs. Heuristic'),
            text: t(`Imagine the British Museum with a giant collection of artefacts 3
            \n•	How could one find the exact location of single artefact?
            •	An algorithm would sequentially examine all objects in all rooms and find the artefact for sure, but potentially need a lot of time
            •	Using a heuristic, e.g., an educated guess, or a rule of thumb, one would maybe find the  artifact much quicker - but maybe also not at all`),
            linkText: t(`* This is known as the “British Museum Technique”; cf. Sternberg, Robert J. , Berlyne, D.E. und Vinacke, W. Edgar. "thought". Encyclopedia Britannica, 14 May. 2008. Retrieved November 22, 2021`),
            link: 'https://www.britannica.com/topic/thought'
        },
    ]
    const cardPadding = 'px-2 py-1';

    // Download Buttons for Modals
    const modalButton1 = <a id="a-DownloadImpact" href={ImpactVsImplementationSvg} download='ImpactVsEaseOfImplementation'>
        <Button>{t('Download')}</Button></a>
    const modalButton2 = <a id="a-DownloadImpact2" href={ImpactVsImplementationCheckedSvg} download='ImpactVsEaseOfImplementation2'>
        <Button>{t('Download')}</Button></a>
    const modalButton3 = <a id="a-DownloadValues" href={ValuesImg} download='ValuesAgainstBaseline'>
        <Button>{t('Download')}</Button></a>
    const modalButton4 = <a id="a-DownloadImpact2" href={HeuristikEng} download='Heuristics'>
        <Button>{t('Download')}</Button></a>

    return (
        <div id="step-quantify">
            <GuideForm guideContent={guideText} active={guideActive} defaultOpen={false}
                onClose={() => setGuideActive(!guideActive)}
            />
            <Modal id='objective1' title={text.modal1Title} size='modal-xl'
                buttons={modalButton1}>
                <img src={ImpactVsImplementationSvg} />
            </Modal>
            <Modal id='objective2' title={text.modal1Title} size='modal-xl'
                buttons={modalButton2}>
                <img src={ImpactVsImplementationCheckedSvg} />
            </Modal>
            <Modal id='values' title={text.modal3Title} size='modal-lg modal-dialog-centered modal-dialog'
                buttons={modalButton3}>
                <img src={ValuesImg} />
            </Modal>
            <Modal id='heuristics' title={text.card4Title} size='modal-xl'
                buttons={modalButton4}>
                <img src={HeuristikEng} />
            </Modal>
            <FormContainer
                title={text.pageTitle}
                openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                width="lg:w-2/3 w-5/6">
                {/* TODO FIX MODAL REFERENCES */}
                <CardGrid gridCol="grid-cols-1 sm:grid-cols-2">
                    <Card title={text.card1Title} text={text.card1Text} justify='justify-top' padding={cardPadding}
                        databstoggle='modal' databstarget='#objective1'>
                        <img src={ImpactVsImplementationSvg} />
                    </Card>
                    <Card title={text.card2Title} text={text.card2Text} justify='justify-top' padding={cardPadding}
                        databstoggle='modal' databstarget='#objective2'>
                        <img src={ImpactVsImplementationCheckedSvg} />
                    </Card>
                    <Card title={text.card3Title} text={text.card3Text} justify='justify-top' padding={cardPadding}
                        databstoggle='modal' databstarget='#values'>
                        <img src={ValuesImg} />
                    </Card>
                    <Card title={text.card4Title} text={text.card4Text} justify='justify-top' padding={cardPadding}
                        databstoggle='modal' databstarget='#heuristics'>
                        <img src={HeuristikEng} />
                    </Card>
                </CardGrid>
            </FormContainer>
        </div>
    );
}

export default Step5Quantify;