import { useState } from "react";
import { faClose, faMagicWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FloatingPanelRight } from "./Panels/FloatingPanelRight";
import VertexForm from "./VertexForm/VertexForm";

/**
 * Displays a general AI button at the bottom right corner of the screen 
 * TODO add click action either in parent or in another component for general AI content
 * TODO delete old 'ButtonAi.js' when this component is implemented
 */
function GeneralAiButton({ }) {
    const [panelActive, setPanelActive] = useState(false);

    return (
        <>
            <FloatingPanelRight
                active={panelActive}
                name="floatingAiPanel"
                rightPosition="right-5"
                topPosition="top-[80px]"
                zIndex="z-[100]"
                height="calc(100% - 170px)"
                minWidth="400"
                handleClose={() => setPanelActive(false)}
            >
                <VertexForm
                    onClose={() => setPanelActive(false)}
                />
            </FloatingPanelRight>
            <div id="generalAiButton"
                className="fixed bottom-10 right-10 z-[100] bg-primary-200 p-2 rounded-full cursor-pointer flex items-center justify-center group transition-all transform duration-300 hover:bg-primary-300"
                onClick={() => setPanelActive(!panelActive)}>
                <FontAwesomeIcon
                    className={`${panelActive ? 'h-4 w-4 p-1' : 'h-7 w-7 p-2'} text-primary-900 group-hover:text-primary-900 group-hover:rotate-6 active:scale-110 group-active:rotate-[25deg] transition-all transform duration-300`}
                    icon={panelActive ? faClose : faMagicWandSparkles}
                />
            </div>
        </>
    );
}

export default GeneralAiButton;