import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { faBolt, faGears } from "@fortawesome/free-solid-svg-icons";
import { faFileClipboard, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import TooltipBase from "components/TooltipBase";
import Card from "components/Card";
import CardGrid from "components/CardGrid";

import TopicContext from "pages/CreateTopicPage/context/TopicContext";

function RatingFlagGrid({ isOverview, editActive }) {
    const [hoverInfo, setHoverInfo] = useState(false);
    const [hoverContent, setHoverContent] = useState();
    const ctx = useContext(TopicContext)
    let activeCardName = ctx.data.rating;
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        headline: t('1-pr-hl', "Problem Rating"),
        description: t('1-pr-desc', "How would you rate your problem?"),
    }
    const rating = [
        {
            name: 'EASY',
            title: t('1-pr-r1t', 'Easy'),
            text: t('1-pr-r1d', 'Environment is clear, outcome is (almost) certain'),
            info: t('1-pr-explanation1', 'E.g.\n Emergency start your car according to a manual'),
            icon: faThumbsUp
        },
        {
            name: 'COMPLICATED',
            title: t('1-pr-r2t', 'Complicated'),
            text: t('1-pr-r2d', 'Environment and/or potential outcomes are uncertain to a medium degree'),
            info: t('1-pr-explanation2', 'E.g.\n Perform maintenance on a power plant'),
            icon: faGears
        },
        {
            name: 'COMPLEX',
            title: t('1-pr-r3t', 'Complex'),
            text: t('1-pr-r3d', 'Environment and/or potential outcomes are uncertain to a high degree'),
            info: t('1-pr-explanation3', 'E.g.\n Developing a car for a new market'),
            icon: faFileClipboard
        },
        {
            name: 'CHAOTIC',
            title: t('1-pr-r4t', 'Chaotic'),
            text: t('1-pr-r4d', 'Environment and potential outcomes are uncertain to an extremely high degree'),
            info: t('1-pr-explanation4', 'E.g.\n Respond to lockdown of car production'),
            icon: faBolt
        },
    ]

    const activeCardObject = rating.filter(x => x.name === activeCardName)[0]

    let [hoverPosition, setHoverPosition] = useState()
    const hoverHandler = (e, index) => {
        const mousePosition = e.clientY;
        // Handles tooltip hight according to mouse position
        const position = mousePosition < 455 ? 'top-6' : 'top-60';
        setHoverPosition(position);
        setHoverInfo(true);
        setHoverContent(rating[index].info);
    }

    const hoverEndHandler = () => {
        setHoverInfo(false);
    }

    const handleClick = (index) => {
        if (activeCardName === index) {
            ctx.itemHandler("rating", undefined);
        } else {
            ctx.itemHandler("rating", index);
        }
    }

    return (
        <div className="flex flex-col lg:flex-row w-full ">
            {/* Cardgrid Area */}
            <div className={`w-full relative`}>
                {hoverInfo && !isOverview &&
                    <div className={`absolute left-full ${hoverPosition}`}>
                        <TooltipBase>
                            {hoverContent}
                        </TooltipBase>
                    </div>}
                {!isOverview && <label className="text-base font-medium text-gray-700 select-none">{text.description}</label>}
                {editActive
                    ? <CardGrid>
                        {rating.map((item, index) => (
                            <Card
                                key={index + item.title}
                                title={item.title} text={item.text} icon={item.icon}
                                index={index}
                                active={activeCardName === item.name ? true : false}
                                onMouseEnter={(e) => hoverHandler(e, index)}
                                onMouseLeave={hoverEndHandler}
                                onClick={() => handleClick(item.name)}
                            />
                        ))

                        }
                    </CardGrid>
                    :
                    // View mode - displays only active card or 'undefined'
                    <Card
                        key={'activeCard'}
                        title={activeCardObject?.title || 'undefined'} text={activeCardObject?.text} icon={activeCardObject?.icon}
                        index={0}
                        noClick={!editActive}
                    />
                }
            </div>
        </div>
    );
}

export default RatingFlagGrid;