import PropTypes from 'prop-types';
import { faCommentDots, faEdit, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ElementWithHoverinfo from './ElementWithHoverinfo';
import DeleteIconButton from './Elements/DeleteIconButton';

function Toolbar({ adminView, toggleEditMode, documentValid = true, editModeActive, handleSave, handleDelete, disableComments = false, hideComments, commentActive, setCommentActive }) {
    const { t } = useTranslation(['common'])

    const elementWidth = 'w-6'

    const handleCommentActive = () => {
        if (!disableComments) {
            setCommentActive(!commentActive);
        }
    }

    return (
        <div id="toolbar" className={`float-right space-x-6 `}>
            {/* Admin Area */}
            {adminView &&
                <>
                    {/* Edit Button */}
                    {toggleEditMode && !editModeActive &&
                        <ElementWithHoverinfo
                            id='toolbar_edit_btn'
                            className={elementWidth}
                            active
                            tooltip={t('Edit')}
                            icon={faEdit}
                            onClick={toggleEditMode}
                        />}
                    {/* Save Button */}
                    {handleSave && editModeActive &&
                        <ElementWithHoverinfo
                            id='toolbar_save_btn'
                            className={elementWidth}
                            active={documentValid}
                            tooltip={t('Save')}
                            icon={faSave}
                            onClick={handleSave}
                        />
                    }
                    {/* Cancel Button */}
                    {editModeActive &&
                        <ElementWithHoverinfo
                            active
                            id='cancelTopicEdit'
                            className={elementWidth}
                            tooltip={t('Cancel')}
                            icon={faTimes}
                            onClick={toggleEditMode}
                        />
                    }
                    {/* Delete Button */}
                    {handleDelete &&
                        <DeleteIconButton id={'deleteTopic'} handleDelete={handleDelete} />
                    }
                </>
            }
            {/* Public Area */}
            {!editModeActive &&
                <>
                    {/* Comment Switch */}
                    {!hideComments && <ElementWithHoverinfo
                        id='commentSwitchBtn'
                        className={elementWidth}
                        onClick={() => handleCommentActive()}
                        active={!disableComments && !commentActive}
                        icon={faCommentDots}
                        tooltip={!commentActive && t('Comments')}
                    />}
                </>
            }
        </div>
    );
}

Toolbar.propTypes = {
    adminView: PropTypes.bool,
    toggleEditMode: PropTypes.func,
    documentValid: PropTypes.bool,
    editModeActive: PropTypes.bool.isRequired,
    handleSave: PropTypes.func,
    handleDelete: PropTypes.func,
    commentActive: PropTypes.bool.isRequired,
    setCommentActive: PropTypes.func.isRequired,
}

export default Toolbar;