import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import GuideForm from "components/Guide/GuideForm";
import Input from "components/InputElement";

import CreateIssueContext from "../context/create-issue-context";

function IssueStatementForm() {
    const { t } = useTranslation();
    const ctx = useContext(CreateIssueContext);
    const text = {
        title: t('3-is-header', "State the issues you want to prioritize"),
        issueTitle: t('3-is-label1', "Issue Title"),
        question: t('3-is-label2', "Question to resolve"),
        titleInfo: t('3-is-tooltip1', 'Short title, e.g., “behaviorial change” - it could correspond to a branch or leaf of your problem tree '),
        questionInfo: t('3-is-tooltip2', 'E.g.: “Which heating technology and behaviour changes do we need to implement to reduce heating emissions by at least 25%?” '),
        prioritizedIssueTips: [
            {
                title: t('3-pi-1t', "Impact"),
                description: t('3-pi-1d', "Issue should be related to an impactful factor")
            },
            {
                title: t('3-pi-2t', "Ease of implementation"),
                description: t('3-pi-2d', "You should be likely to implement a solution (yet to be found) for the issue in the first place")
            },
            {
                title: t('3-pi-3t', "Question"),
                description: t('3-pi-3d', "You can frame your issue in an open or closed question")
            },
            {
                title: t('3-pi-4t', "Specificity"),
                description: t('3-pi-4d', "Try to ask a question as specific and detailed as possible")
            },
            {
                title: t('3-pi-5t', "Granularity"),
                description: t('3-pi-5d', "Find an insightful level of granularity – you might want to review the section on how to “Select your Portfolio”")
            },
        ],

    }
    const guideText = [
        {
            header: t('1-pi-g1t', 'Prioritized issue: Definition?'),
            text: t('1-pi-g1d', `An issue is an important unresolved question related to an impactful factor of your problem structure that you can reasonably address Source: Conn, C. &amp; McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley; cf. Chapter 4, page 91 ff.`)
        },
        {
            header: t('1-pi-g2t', 'Hypothesis-driven work'),
            text: t('1-pi-g2d', `The entire problem solving approach is hypothesis-driven*, so you should make plausible statements based on assumptions early on in the process, sometimes even educated guesses.
            This method is usually quick and resource-efficient, especially when you have deep expertise in your team, but also needs interactive iterations until the path and the numbers become stable
            \n*A resolution hypothesis is a proposed resolution for the prioritized issue which cannot currently be proven right away, but which should respect certain quality criteria (e.g., being testable) `)
        },
    ];
    const [guideActive, setGuideActive] = useState(false);

    return (
        <div id="issueStatementForm">
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4'} />
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                title={text.title}>
                {/* Title Input */}
                <Input id='IssueTitleInput'
                    tooltip={text.titleInfo}
                    label={text.issueTitle}
                    value={ctx.data.title}
                    onChange={ctx.handleChange('title')}
                />
                <Input id='questionsToResolve'
                    tooltip={text.questionInfo}
                    tips={text.prioritizedIssueTips}
                    inputType={'textarea'}
                    label={text.question}
                    value={ctx.data.question}
                    onChange={ctx.handleChange("question")}
                />
            </FormContainer>
        </div>
    );
}

export default IssueStatementForm;