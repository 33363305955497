import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import EditableRow from "components/EditableRow";
import TopicContext from "pages/CreateTopicPage/context/TopicContext";

function StakeholderRow(props) {
    const { t } = useTranslation(['problemDefinition']);
    const ctx = useContext(TopicContext);
    const thisRow = ctx.data.stakeholders[props.index];
    const oneRow = ctx.data.stakeholders?.length === 1;
    const tableName = "stakeholders";

    // TODO: make it work with translation
    const [rowData, setRowData] = useState([
        { id: 0, name: 'stakeholder', value: thisRow.stakeholder, type: 'area', style: 'w-[15%]' },
        {
            id: 1, name: 'influence', value: thisRow.influence, type: 'selectMenu', style: 'w-[140px]',
            itemList: [null,
                "VERY_HIGH",
                "HIGH",
                "MEDIUM",
                "LOW",
                "VERY_LOW",
            ],
            translationFile: 'common'
        },
        { id: 2, name: 'affectedness', value: thisRow.affectedness, type: 'area', style: 'w-[38%]' },
        { id: 3, name: 'needs', value: thisRow.needs, type: 'area', style: 'w-[37%]' },
        // { id: 4, name: 'reasoning', value: thisRow.reasoning, type: 'area', style: 'w-20' },
    ])

    const deleteHandler = () => {
        ctx.deleteRowHandler(tableName, thisRow.id);
    }

    const changeHandler = (rowIndex, name, data, itemId) => {
        let tempData = [...rowData];
        tempData[itemId].value = data;
        setRowData(tempData);

        ctx.rowHandler(tableName, name, rowIndex, data);
    }

    return (
        <EditableRow
            editActive={props.editActive}
            oneRow={oneRow}
            rowId={thisRow.id}
            rowIndex={props.index}
            rowData={rowData}
            noDelete={!props.editActive}
            delete={deleteHandler}
            changeHandler={changeHandler}
            intitialMinRow={2}
        />
    );
}

export default StakeholderRow;