// Frameless Input component with readonly switch to 'label' component
// Frame is shown when input is focused 
// Optional with bottom line [borderBottom={true}]
function InputFrameless({ id, editActive, className, padding, type, value, onChange, placeholder, style, borderBottom, tabIndex }) {
    return (
        <div>
            {editActive ?
                <input
                    id={id}
                    readOnly={!editActive}
                    className={`
                        ${className}
                        ${editActive && "focus:border-1 focus:border-blue-200 focus:bg-primary-100"}
                        ${borderBottom ? 'border-b border-t-0 border-l-0 border-r-0 border-gray-300' : 'border-0'}
                    text-base font-small p-0`}
                    type={type}
                    value={value || undefined}
                    onChange={onChange}
                    placeholder={placeholder || '...'}
                    style={style}
                    tabIndex={tabIndex}
                />
                :
                <label className={`${className} ${padding} text-base font-small text-gray-700`}>
                    {value || placeholder}
                </label>
            }

        </div>
    );
}

export default InputFrameless;