import { executiveSummaryActionTypes as types } from 'constants/executive-summary.action.types';
  
const initialState = (window.Cypress && window.initialState && window.initialState.executiveSummaryReducer) || {};

export function executiveSummaryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_EXECUTIVE_SUMMARY_REQUEST:
    case types.UPDATE_EXECUTIVE_SUMMARY_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.GET_EXECUTIVE_SUMMARY_SUCCESS:
    case types.UPDATE_EXECUTIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        executiveSummary: payload
    };
    case types.GET_EXECUTIVE_SUMMARY_FAILURE:
    case types.UPDATE_EXECUTIVE_SUMMARY_FAILURE:
    default:
      return state;
  }
}
  