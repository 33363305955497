import { useState, Fragment } from 'react';
import { useTranslation } from "react-i18next";
import InputFrameless from 'components/InputFrameless';

/**
 * 
 * @param {*}  
 * @returns Stat tile for Number tiles and text tiles, those can be editable or readonly
 * Every tile has a '%' badge, which can be also editable 
 */
function EditableStatTile({ name, value, unit, type, percentage, percentageEditable, onPercentageChange, editActive, onChange, onUnitChange, readOnly, tabIndex, badgeColor }) {
    const { t } = useTranslation();
    const [percentageEditActive, setPercentageEditActive] = useState(false);

    const handlePercentageEdit = () => {
        if (percentageEditable) {
            setPercentageEditActive(true);
        }
    }

    return (
        <div id='editableStatTile' key={name} className="relative px-2 py-2 sm:pl-1 sm:pr-2 border-t border-gray-200 w-full">
            <dt className="text-xs font-medium text-gray-700 uppercase ml-1">{name}
                {/* Percentage Badge */}
                <div className='absolute top-1 right-1'>
                    {(!percentageEditActive || !percentageEditable) &&
                        <div
                            onClick={handlePercentageEdit}
                            className={`${badgeColor || "bg-green-100 text-green-800"} 
                            ${percentageEditable ? 'cursor-pointer' : 'cursor-default'}
                    inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium ml-2 md:mt-2 lg:mt-0 whitespace-nowrap`}>
                            {isNaN(percentage) ? "? " : percentage}%
                        </div>}
                    {percentageEditActive &&
                        <input
                            value={percentage}
                            autoFocus={true}
                            onBlur={() => setPercentageEditActive(false)}
                            onKeyDown={(e) => { (e.keyCode === 13) && setPercentageEditActive(false) }}
                            type="number"
                            maxLength={10}
                            className="w-16 p-0 text-center border-0 
                                    focus:border-1 focus:border-blue-200 focus:bg-primary-100"
                            onChange={onPercentageChange} />}
                </div>
            </dt>
            <div className={`mt-2 items-baseline text-lg font-medium text-primary-600`}>
                {/* Output Label for Readonly Stats */}
                {readOnly ?
                    <div className='flex justify-end'>
                        <label className='text-base font-medium text-gray-600'>
                            <span className='inline-block text-right '>{value}</span>
                            <span className='ml-2'>{unit}</span>
                        </label>
                    </div>
                    :
                    <div className='w-full flex justify-end'>
                        {/* Input for editable Stats */}
                        {type === 'number' &&
                            <div className='flex'>
                                {/* Main Stat Number */}
                                <InputFrameless
                                    editActive={editActive}
                                    value={value}
                                    type="number"
                                    className="mr-1 text-right"
                                    borderBottom={true}
                                    style={{ width: '99%' }}
                                    onChange={onChange}
                                    placeholder={"0"}
                                    tabIndex={tabIndex} />
                                {/* Unit Input*/}
                                <InputFrameless
                                    id={'editableStatTile_unit' + name}
                                    editActive={editActive}
                                    className="text-left w-16"
                                    type="text"
                                    value={unit}
                                    onChange={onUnitChange}
                                    placeholder={t('1-pm-label3', "Unit")}
                                    tabIndex={tabIndex}
                                />
                            </div>}
                        {/* Input for text only */}
                        {type === 'text' &&
                            <InputFrameless
                                editActive={editActive}
                                value={value || ''}
                                className='w-full'
                                borderBottom={true}
                                type="text"
                                onChange={onChange}
                                placeholder={"..."}
                                tabIndex={tabIndex}
                            />
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default EditableStatTile;