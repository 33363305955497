import { useEffect, useState } from 'react';
import CardGrid from "components/CardGrid";
import ProfileProblemCard from "./ProfileProblemCard";
import { useTranslation } from "react-i18next";

import { parseItem } from 'utils/session-storage';

function Userclusters({
    userName,
    problemMatches,
    ownProblems,
    owner,
}) {
    const { t } = useTranslation(['foren']);

    const [currentCommunity, setCurrentCommunity] = useState(null)

    useEffect(() => {
        if (parseItem('community')?.id && parseItem('community')?.id != currentCommunity) {
          setCurrentCommunity(parseItem('community').id)
        }
      }, [sessionStorage.getItem('community')]);

    // TODO Translation
    // TODO visibility vor profile owner vs visitor 
    return (
        <>
            {/* Created by User */}
            {ownProblems?.length > 0 &&
                <>
                    <p id="createdByUser">{t('fo-userTopics', 'Topics created by')} {userName}</p>
                    <CardGrid className='text-center' gridCol='grid-cols-1 sm:grid-cols-2 mt-5'>
                        {ownProblems.filter(x => x.communityId === currentCommunity).map((problem) => (
                            <ProfileProblemCard key={problem.id} problem={problem} isOwner />
                        ))}
                    </CardGrid>
                </>}
            {/* Related */}
            {owner &&
                <>
                    <p>{t('fo-relatedTopics', 'Related Topics')} {problemMatches.length}</p>
                    <CardGrid className='text-center' gridCol='grid-cols-1 sm:grid-cols-2'>
                        {problemMatches.map((problem) => (
                            <ProfileProblemCard key={problem.id} problem={problem} />
                        ))}
                    </CardGrid>
                </>}
        </>
    );
}

export default Userclusters;