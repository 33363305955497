import { React, useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";

import Container from 'components/Container';

import IssuesList from 'pages/IssuesListPage';
import ProblemStructure from 'pages/CreateProblemStructure';
import TopicDefinitionOverview from 'pages/CreateTopicPage/Overview/TopicDefinitionOverview';
import TopicProvider from 'pages/CreateTopicPage/context/TopicProvider';
import MinutesTab from './Minutes/indexMinutesTab';
import ExecutiveSummary from 'pages/ProblemTabOverviewPage/ExecutiveSummary/indexExecutiveSummary';
import ToolbarTopic from 'components/ToolbarTopic';

function ProblemTabOverviewPage(props) {
    const { t } = useTranslation(['problemDefinition', 'tabs']);

    const location = useLocation();
    const query = location.search.replace('?id=', '');
    const { problemId } = useParams();
    const [problemTitle, setProblemTitle] = useState('')
    const [userList, setUserList] = useState([])
    const [relevantProfiles, setRelevantProfiles] = useState([])
    const [isOwner, setIsOwner] = useState(false)
    const problemDefinitionFinished = true

    useEffect(() => {
        const { title, users, relevantProfiles, role } = props?.problem || {};
        if (title) setProblemTitle(title);
        if (users) setUserList(users);
        if (relevantProfiles) setRelevantProfiles(relevantProfiles);
        if (role === 'OWNER') setIsOwner(true);
    }, [props?.problem]);

    // console.log('data', props.problem)

    const handleDelete = (e) => {
        // TODO delete the whole deep topic
    };

    const [tabs, setTabs] = useState([
        {
            name: t('2-probdef', 'Problem Definition'), href: '?id=problemDefinition', id: 'problemDefinition',
            component: problemDefinitionFinished ?
                <TopicDefinitionOverview
                    problemId={problemId}
                    tabview
                    isDeepTopic />
                :
                <TopicDefinitionOverview
                    problemId={problemId}
                    tabview />
        },
        // { name: t('2-probstru', 'Problem Structure'), href: '?id=problemStructure', id: 'problemStructure', count: 1, component: <ProblemStructure problemId={problemId} /> },
        // { name: t('2-iss', 'Issues'), href: '?id=issues', id: 'issues', count: 4, component: <IssuesList /> },
        // { name: t('2-app', 'Approaches'), href: '?id=approaches', count: 0, id: 'approaches' },
        // { name: t('2-decspa', 'Decision Space'), href: '?id=decisionSpace', count: 0, id: 'decisionSpace' },
        { name: t('summary', 'Executive Summary', {ns: 'tabs'}), href: '?id=summary', count: 0, id: 'summary', component: <ExecutiveSummary /> },
        { name: t('Minutes', {ns: 'tabs'}), href: '?id=minutes', count: 0, id: 'minutes', component: <MinutesTab /> },
        // { name: 'Links', href: '?id=links', id: 'links' },
    ]);


    const [activeTab, setActiveTab] = useState(query || 'problemDefinition');

    const onClickHandler = (e, index) => {
        // e.preventDefault();
        setActiveTab(location.search.replace('?id=', ''))
    }


    return (
        <Container id='problemOverviewTabs'>
            <TopicProvider problemId={problemId}>
                <span id='deepDiveTitle' className='text-xl leading-7'>{problemTitle}</span>
                <ToolbarTopic userList={userList} relevantProfiles={relevantProfiles} adminView={isOwner} handleDelete={handleDelete}/>
                <ul className="nav nav-tabs flex flex-col space-x-0.5 md:flex-row flex-wrap list-none border-b-0 pl-0 mt-3" id="tabs-tab"
                    role="tablist">
                    {tabs.map((tab, index) => (
                        <li key={tab.id} className="nav-item" role="presentation">
                            <a className={`
                                ${(activeTab === tab.href.replace('?id=', ''))
                                    ? "border-b-2 border-primary-600 bg-primary-50"
                                    : ""}
                                block font-medium text-base sm:text-sm border-x-0 border-t-0 px-5 py-3 rounded-t-lg focus:outline-none hover:bg-gray-100`}
                                href={location.pathname + tab.href}
                                onClick={(e) => onClickHandler(e, index)}>
                                {tab.name}

                                {tab.count ? (
                                    <span
                                        className={`
                                        ${tab.current ? 'bg-indigo-100 text-primary-600' : 'bg-gray-100 text-gray-900'} 
                                            hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block`}>
                                        {tab.count}
                                    </span>
                                ) : null
                                }
                            </a>
                        </li>
                    ))}
                </ul>
                <div className='pt-4 border-t-2 border-r-gray-400 -mt-[2px]'>{tabs.map((tab) => (
                    (activeTab === tab.href.replace('?id=', '')) ? <div key={tab.id}>{tab.component}</div> : null
                ))}
                </div>
            </TopicProvider>
        </Container>
    );
}

// TODO: check prop types missing

const mapStateToProps = (state) => ({
    problem: state.problemReducer.problem
});

export default connect(mapStateToProps, null)(ProblemTabOverviewPage);
