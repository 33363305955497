import { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MiniSearch from "minisearch";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { useParams, useHistory } from 'react-router-dom';

import MinutesTable from "./MinutesTable";
import { AddTableButton } from "components/Elements/AddTableButton";
import { updateNestedItem } from "hooks/updateNestedItem";
import FormContainer from "components/FormContainer";
import Toolbar from "components/Toolbar";
import { ResizablePanelRight } from "components/Panels/ResizablePanelRight";
import CommentOverlay from "components/Comment/CommentIndex";

import { alertActionTypes } from 'constants/alert.action.types';
import { getMeetingMinutes } from "actions/meeting-minutes.get.actions";
import { updateMeetingMinutes } from "actions/meeting-minutes.update.actions";
import { uploadFile } from 'actions/file.upload.actions';
import { deleteFile } from 'actions/file.delete.actions';

/**
* Minutes Tab
* Displays all editable minutes tables, 
* Can add new tables, and tasks for each table, as well as ressources 
* Can search through all tables
 */
function MinutesTab({
    meetingMinutes,
    profile,
    getMeetingMinutes,
    updateMeetingMinutes,
    uploadFile,
    deleteFile
}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation(['minutes']);
    const { clusterId, problemId } = useParams();
    const [editActive, setEditActive] = useState(false);
    const [commentActive, setCommentActive] = useState(false);
    const [copyLastMeeting, setCopyLastMeeting] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [meetingMinutesData, setMeetingMinutesData] = useState({id: uuidv4()})
    const [meetingTablesData, setMeetingTablesData] = useState([{
        id: uuidv4(),
        body: null,
        date: null,
        type: 'INFORMATION',
        item:null,
        documents: [],
        links: [],
        tasks: []
    }])
    const [hideCommentButton, setHideCommentButton] = useState(true);

    useEffect(() => {
        if (meetingMinutes?.id && meetingMinutes?.createdTs) {
            setHideCommentButton(false);
        } 
    }, [meetingMinutes]);

    const miniSearch = useMemo(() => new MiniSearch({
        fields: ["body", "date", "type",],
        storeFields: ["id", "body", "date", "type", "tasks"],
        searchOptions: {
            fuzzy: 0.2,
            prefix: true
        }
    }), []);

    const filteredTables = searchQuery
        ? miniSearch.search(searchQuery).map(result => result)
        : meetingTablesData;

    useEffect(() => {
        miniSearch.removeAll();
        miniSearch.addAll(meetingTablesData.map(table => ({
            ...table,
            id: String(table.id)
        })));
    }, [meetingTablesData, miniSearch]);


    // fetching executive summary from server initially
    useEffect(() => {
        if (problemId) {
            getMeetingMinutes(problemId)
        }
    }, [problemId])

    useEffect(() => {
        if (editActive) {
            setCommentActive(false);
        }
    }, [editActive]);

    // sync meetingTablesData and meetingMinutes from server
    useEffect(() => {
        // copying the whole header data excepts meetings
        if (meetingMinutes) {
            // let meetingMinutesWithoutMeetings = Object.fromEntries(
            //     Object.entries(meetingMinutes).filter(([key]) => key !== 'meetings')
            // )
            if (!('id' in meetingMinutes)) {
                setMeetingMinutesData({...meetingMinutes, id: uuidv4()})
            } else {
                setMeetingMinutesData({...meetingMinutes})
            }
        } else {
            setMeetingMinutesData({id: uuidv4()})
        }

        if(meetingMinutes?.meetings?.length > 0) {
            setMeetingTablesData(meetingMinutes.meetings);
        } else {
            setMeetingTablesData([{
                id: uuidv4(),
                body: null,
                date: null,
                type: 'INFORMATION',
                item:null,
                documents: [],
                links: [],
                tasks: []
            }]);
        }
    }, [meetingMinutes]);

    // TODO: the same function is used in TopicDefinitionOverview, but it cannot be recycled somehow, bacause it does not await
    // sync documents
    function syncDocuments() {

        // local copy of the original data
        let data = [ ...meetingTablesData ]

        data.forEach(meeting => {

            const documentUploadList = meeting.documents?.filter(t => t.status === 'UPLOAD_PENDING');
            const documentDeleteList = meeting.documents?.filter(t => t.status === 'DELETE_PENDING');
            const documentKeepListOrig = meeting.documents?.filter(t => t.status !== 'UPLOAD_PENDING' && t.status !== 'DELETE_PENDING');

            const uploadPromises = documentUploadList?.map(doc =>
                uploadFile(meeting.id, doc.filename, doc.file)
                    .then(response => {
                        return Promise.resolve({ ...doc, status: 'UPLOADED', response: response });
                    })
                    .catch(error => {
                        return Promise.reject({ ...doc, status: 'UPLOAD_ERROR', response: error });
                    })
            );

            const deletePromises = documentDeleteList?.map(doc =>
                deleteFile(meeting.id, doc.filename)
                    .then(response => {
                        return Promise.resolve({ ...doc, status: 'DELETED', response: response });
                    })
                    .catch(error => {
                        return Promise.reject({ ...doc, status: 'DELETE_ERROR', response: error });
                    })
            );

            // Wait for all uploads to complete and return the updated document list
            Promise.allSettled(uploadPromises.concat(deletePromises)).then(results => {

                const fulfilled_upload = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'UPLOADED').map(result => result?.value);
                const fulfilled_delete = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'DELETED').map(result => result?.value);
                const rejected_upload = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'UPLOAD_ERROR').map(result => result?.reason);
                const rejected_delete = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'DELETE_ERROR').map(result => result?.reason);

                // excluding from keep list DELETED files
                const documentKeepList = documentKeepListOrig?.filter((d) => {
                    return fulfilled_delete?.every((t) => {
                        return t.id !== d.id;
                    });
                });

                const documentList = documentKeepList.concat(fulfilled_upload, rejected_upload, rejected_delete);

                // updating data on the fly?
                data = data.map((obj, index) => {
                    if (obj.id === meeting.id) {
                      return { ...obj, documents: documentList };
                    }
                    return obj; // Return the original object if not updating
                  });

                // sync data and meetingTableData
                setMeetingTablesData(prev => prev.map(table =>
                    table.id === meeting.id
                        ? { ...table, documents: [...documentList] }
                        : table
                ));

                // sync data with backend
                updateMeetingMinutes(problemId, {...meetingMinutesData, meetings: [...data]}).then(
                    response => {
                        if (rejected_upload?.length > 0 || rejected_delete.length > 0) {
                            dispatch({ type: alertActionTypes.ERROR, payload: 'some files could not be uploaded / deleted, please redo' })
                            history.push(`/clusters/${clusterId}/problems/${problemId}?id=minutes`)
                        }
                    }
                )
            });
        });
    }

    const handleUpdateTables = (tableId, updatedTable) => {
        const updatedTables = meetingTablesData.map((table) =>
            table.id === tableId ? { ...updatedTable } : table
        );
        setMeetingTablesData(updatedTables);
    }

    const addTable = () => {
        let newTable = {}
        if (copyLastMeeting) {
            const lastTable = meetingTablesData[meetingTablesData.length - 1]
            newTable = {
                id: uuidv4(),
                body: lastTable.body,
                date: lastTable.date,
                type: lastTable.type,
                item: null,
                documents: [],
                links: [],
                tasks: []
            }
        } else {
            newTable = {
                id: uuidv4(),
                body: null,
                date: null,
                type: 'INFORMATION',
                item: null,
                documents: [],
                links: [],
                tasks: []
            }
        }
        setMeetingTablesData([...meetingTablesData, newTable])
    }

    const handleDeleteTable = (tableId) => {
        const updatedTables = meetingTablesData.filter((table) => table.id !== tableId)
        setMeetingTablesData(updatedTables)
    }

    const updateTask = (tableId, taskId, field, value) => {
        updateNestedItem(setMeetingTablesData, tableId, 'tasks', taskId, field, value);
    };

    const deleteTask = (tableId, taskId) => {
        console.log('delete task', tableId, taskId)
        setMeetingTablesData(prev => prev.map(table =>
            table.id === tableId
                ? { ...table, tasks: table.tasks.filter(task => task.id !== taskId) }
                : table
        ));
    };

    const addTask = (tableId) => {
        const newTask = {
            id: uuidv4(),
            task: null,
            responsible: null,
            deadline: null,
            status: 'OPEN',
            comment: null,
        }
        setMeetingTablesData(prev => prev.map(table =>
            table.id === tableId
                ? { ...table, tasks: [...table.tasks, newTask] }
                : table
        ));
    }

    const handleSave = () => {
        setEditActive(false)
        updateMeetingMinutes(problemId, {...meetingMinutesData, meetings: [...meetingTablesData]} ).then(
            response => {
                meetingTablesData.forEach(meeting => {
                    if (meeting?.documents?.length > 0) {
                        syncDocuments();
                    }
                  });
            }
        )
    }

    return (
        <>
            { meetingMinutesData?.id && meetingMinutesData?.createdTs &&
            <ResizablePanelRight active={commentActive}>
                <CommentOverlay
                    active={commentActive}
                    profile={profile}
                    parentId={meetingMinutesData.id}
                    onClose={() => setCommentActive(false)}
                />
            </ResizablePanelRight> }
            <FormContainer className="relative" width='w-full min-w-[840px]'>
                <div className="flex absolute right-0">
                    <input
                        type="text"
                        placeholder={t("filter", "Search Meetings...")}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md mr-4 -mt-2"
                    />
                    <Toolbar
                        adminView //TODO add
                        commentActive={commentActive}
                        hideComments={hideCommentButton}
                        setCommentActive={setCommentActive}
                        toggleEditMode={() => setEditActive(!editActive)}
                        editModeActive={editActive}
                        handleSave={handleSave}
                    />
                </div>
                <div className="pt-10">
                    {filteredTables.map((table, index) => {
                        const hideHeader = index > 0 && filteredTables[index - 1].tasks.length === 0;
                        return (
                            <MinutesTable
                                key={index}
                                editActive={editActive}
                                updateTables={handleUpdateTables}
                                table={table}
                                deleteActive={filteredTables.length > 1}
                                deleteTable={handleDeleteTable}
                                updateTask={updateTask}
                                deleteTask={deleteTask}
                                addTask={addTask}
                                hideHeader={hideHeader}
                            />
                        );
                    })}
                    {filteredTables.length === 0 && <
                        div className="text-center text-gray-500 my-10">
                        {t('noMeetings', 'No Meetings found')}
                    </div>}
                    {editActive &&
                        <div className="flex float-right items-center">
                            <input
                                name="copyLastMeetingCheckbox"
                                className={`rounded-sm m-auto ${editActive ? 'focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded' : 'bg-gray-200 border-gray-400'} `}
                                type="checkbox"
                                checked={copyLastMeeting}
                                onChange={() => setCopyLastMeeting(!copyLastMeeting)}
                            />
                            <label htmlFor="copyLastMeetingCheckbox" className="ml-2 mr-4 text-gray-700">
                                {t('copyMeeting', 'Copy last meeting')}
                            </label>
                            <AddTableButton addTable={addTable} text={t('', 'Add Meeting')} />
                        </div>}
                </div>
            </FormContainer>
        </>
    );
}

MinutesTab.propTypes = {
    getMeetingMinutes: PropTypes.func.isRequired,
    updateMeetingMinutes: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    meetingMinutes: state.meetingMinutesReducer.meetingMinutes,
    profile: state.profileReducer.profile
})

export default connect(mapStateToProps, {
    getMeetingMinutes,
    updateMeetingMinutes,
    uploadFile,
    deleteFile
})(MinutesTab)