const meetingMinutesActionTypes = {
    GET_MEETING_MINUTES_REQUEST: 'GET_MEETING_MINUTES_REQUEST',
    GET_MEETING_MINUTES_SUCCESS: 'GET_MEETING_MINUTES_SUCCESS',
    GET_MEETING_MINUTES_FAILURE: 'GET_MEETING_MINUTES_FAILURE',

    UPDATE_MEETING_MINUTES_REQUEST: 'UPDATE_MEETING_MINUTES_REQUEST',
    UPDATE_MEETING_MINUTES_SUCCESS: 'UPDATE_MEETING_MINUTES_SUCCESS',
    UPDATE_MEETING_MINUTES_FAILURE: 'UPDATE_MEETING_MINUTES_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { meetingMinutesActionTypes };