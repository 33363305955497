import PropTypes from 'prop-types';

function AddTableButton({ addTable, text, className }) {
    return (
        <button
            type="button"
            onClick={addTable}
            className={`text-primary-600 bg-blue-50 px-4 py-2 rounded-md float-right min-w-[300px] ${className}`}
        >
            {text}
        </button>
    );
}

AddTableButton.propTypes = {
    addTable: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export { AddTableButton };