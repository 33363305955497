const minutesDict = {
    "mb":"Besprechungsformat",
    "dt":"Datum",
    "ty":"Typ",
    "it":"Inhalt",
    "at":"Aufgaben",
    'res':"Ressourcen",
    "addRes":"Ressourcen hinzufügen",
    "addTask":"Aufgaben hinzufügen",
    "ta":"Aufgabe",
    "re":"Verantwortlicher",
    "de":"Deadline",
    "st":"Status",
    "co":"Kommentar",
    "DECISION":"Entscheidung",
    "OPEN":"Offen",
    "IMPLEMENTED":"Implementiert",
    'copyMeeting':'Besprechung kopieren',
    'filter':'Besprechungen filtern',
    'noMeetings':'Keine Besprechungen gefunden',
}

export default minutesDict