import GuideButton from "components/Guide/GuideButton"

// props: title, {children}, 
// width, style, guideWidth, guideActive [for styling changes]
function FormContainer({ id, className, ...props }) {
    const width = (props.width || 'md:w-2/3 w-full max-w-2xl');
    const guideWidth = (props.guideWidth || 'w-3/5 md:w-2/3 xl:w-3/4 2xl:w-4/5')
    const guide = props.guide;
    // This prop handles styling changes, necessary for bigger components
    const guideActive = props.onGuideActive;

    //ml-1 no longer working, space-y-5 was also not working -4 for now
    return (
        <div id={"formContainerWrapper_" + id}
            className={`flex flex-col space-y-4 transition-all duration-300 ease-in-out h-full
            mx-auto my-2 lg:ml-2
            ${guideActive ? guideWidth : width}
            ${className}`}
            style={props.style}>
            <div className="flex justify-between">
                <span id="formContainer_title" className='text-xl leading-7'>{props.title}</span>
                {guide && <GuideButton onClick={props.openGuide} />}
            </div>
            {props.children}
        </div>);
}

export default FormContainer;