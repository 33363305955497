import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Label from './Elements/Label';
import FileCommentList from './FileCommentList';
import LinkList from './LinkList';
import { useTranslation } from "react-i18next";

// Modal for adding documents and links to an element
function ResourceModal({ id, title, size = 'modal-lg', topPosition = 'top-40', editActive, linkList, changeLinkList, documentList, changeDocumentList }) {
    const { t } = useTranslation(['problemDefinition', 'common'])

    return (
        <Modal id={id}
            title={title}
            size={`${size}`}
            innerSize={'max-h-[450px] overflow-y-scroll'}
            topPosition={topPosition}
            backdrop
            className={'min-w-688'}>
            <div className="p-2 space-y-12">
                <div>
                    <Label htmlFor="relatedLinks">
                        {t('docs', 'Related Documents', { ns: 'common' })}
                    </Label>
                    <FileCommentList
                        editMode={editActive}
                        documentList={documentList}
                        setTempFiles={changeDocumentList}
                    />
                </div>
                <div>
                    <Label htmlFor="relatedLinks">
                        {t('1-ps-linkList', 'Related Links', { ns: 'problemDefinition' })}
                    </Label>
                    <LinkList
                        editMode={editActive}
                        list={linkList}
                        changeList={changeLinkList}
                    />
                </div>
            </div>
        </Modal>
    );
}

ResourceModal.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
    topPosition: PropTypes.string,
    editActive: PropTypes.bool,
    linkList: PropTypes.array,
    changeList: PropTypes.func,
    documentList: PropTypes.array,
    changeDocumentList: PropTypes.func,
};

export default ResourceModal;