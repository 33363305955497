import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClustersListView from 'pages/ClustersListPage';

import NewProfile from 'pages/UserProfilePage/NewProfile';
import { parseItem } from 'utils/session-storage'

import { listUserClusters } from "actions/clusters.list.actions";
import { listSegments } from "actions/segments.list.actions";
import { listValueChains } from 'actions/value-chains.list.actions';
import AlertPopup from './AlertPopup';


function HomePage({
  profile: { profile },
  communities: { communities },
  listUserClusters,
  listSegments,
  listValueChains
}) {

  const [currentCommunity, setCurrentCommunity] = useState(null)

  useEffect(() => {
    listSegments()
  }, []);

  useEffect(() => {
    listValueChains()
  }, []);

  useEffect(() => {
    if (communities?.length > 0) {
      if (parseItem('community')?.id) {
        let matchedCommunity = communities.find(item => item.id === parseItem('community').id)
        if (matchedCommunity?.title) {
          document.title = matchedCommunity.title
        }
      }
    }
  }, [communities, sessionStorage.getItem('community')]);

  useEffect(() => {
    if (parseItem('community')?.id && parseItem('community')?.id != currentCommunity) {
      listUserClusters(parseItem('community').id)
      setCurrentCommunity(parseItem('community').id)
    }
  }, [sessionStorage.getItem('community')]);



  return (
    profile ? (
      <>
        <AlertPopup />
        <ClustersListView />
      </>
    ) : (
      <NewProfile />
    )
  )
}

HomePage.propTypes = {
  profile: PropTypes.object.isRequired,
  communities: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  listUserClusters: PropTypes.func.isRequired,
  listSegments: PropTypes.func.isRequired,
  listValueChains: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  communities: state.communityReducer,
  profile: state.profileReducer
});

export default connect(mapStateToProps, { listUserClusters, listSegments, listValueChains })(HomePage);
