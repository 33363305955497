import ProptTypes from 'prop-types';
import { faUserGroup, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ElementWithHoverinfo from './ElementWithHoverinfo';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import TeamTable from './TeamTable';
import DeleteIconButton from './Elements/DeleteIconButton';

function ToolbarTopic({ adminView, handleDelete, userList, relevantProfiles, className }) {
    const { t } = useTranslation(['common'])

    const addUser = (user) => {
        // TODO
        console.log('requested user add')
    }


    return (
        <>
            <Modal id='modalTeam'
                size='modal-xl'
                backdrop>
                <TeamTable
                    showOrganisationRoles
                    showCommunityRoles
                    showclusterRoles
                    showProblemRoles
                    showLastLogin
                    users={userList}
                />
            </Modal>
            <Modal id='addMember'
                size='modal-xl'
                backdrop>
                <TeamTable
                    // showAddButton
                    users={relevantProfiles}
                    addUser={addUser}
                />
            </Modal>
            <div id="toolbar" className={`flex float-right space-x-6 ${className} `}>
                {/* Delete Button */}
                {adminView && handleDelete &&
                    <span className='mr-4'>
                        <DeleteIconButton id={'deleteDeepTopic'} handleDelete={handleDelete} />
                    </span>
                }
                {/* Team Table */}
                <ElementWithHoverinfo
                    id='teamMember'
                    modal='#modalTeam'
                    icon={faUserGroup}
                    active={userList?.length > 0}
                    number={userList?.length}
                    tooltip={t('Team')}
                />
                {/* Add Member */}
                <ElementWithHoverinfo
                    id='addMember'
                    icon={faUserPlus}
                    tooltip={t('Add Member')}
                    active={relevantProfiles?.length > 0}
                    number={relevantProfiles?.length}
                    modal={'#addMember'}
                />
            </div>
        </>
    );
}

ToolbarTopic.propTypes = {
    adminView: ProptTypes.bool,
    handleDelete: ProptTypes.func,
    userList: ProptTypes.array.isRequired,
    relevantProfiles: ProptTypes.array,
}

export default ToolbarTopic;