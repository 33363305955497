import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CardFrame from 'components/CardFrame';

const hoverInfoClass = 'absolute top-8 -left-6 scale-0 transition-all rounded bg-primary-100 px-2 py-1 text-sm text-primary-700 group-hover:scale-100 border border-primary-200'

function ProfileProblemCard({ problem, isOwner }) {
    
    return (
        <CardFrame>
            <div className="">
                <div className='pt-1 pb-1 border-y-gray-300 border-b text-sm text-gray-700' >{problem.communityTitle}</div>
                <a id='profileclusterLink' href={'/clusters/' + problem.clusterId}>
                    <div className=" pt-1 pb-1 text-sm hover:bg-primary-200 hover:font-bold">{problem.clusterTitle}</div>
                </a>
                <a id='profileProblemLink' href={'/clusters/' + problem.clusterId + '/topics/' + problem.id}>
                    <div className="relative bg-gray-100 font-semibold mb-2 py-1 border-y-gray-300 border-y hover:bg-green-200">
                        {problem.title}
                        {isOwner &&
                            <span className="group text-slate-800 absolute left-2 top">
                                <FontAwesomeIcon icon={faUser} />
                                <span className={hoverInfoClass}>
                                    Owner
                                </span>
                            </span>
                        }
                    </div>
                    <p className="pt-1 pb-4">{problem.statement}</p>
                </a>

                {/* <div className="pb-2">
                    {segmentSelected?.length > 0 && <DisplayTagList list={segmentSelected} center />}
                    {valueChainSelected?.length > 0 && <DisplayTagList list={valueChainSelected} center />}
                </div> */}
            </div>
        </CardFrame>
    );
}

export default ProfileProblemCard;