import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTree, faDiamondTurnRight, faLink } from "@fortawesome/free-solid-svg-icons";

import CardGrid from "components/CardGrid";
import Card from "components/Card";
import FormContainer from "components/FormContainer";

import minimalTreeImg from '../Images/MinimalTree.png';
import externalDocumentImg from '../Images/TreeExternalDocumentDrawn.png';
import teeTutorialImg from '../Images/TreeTutorial.png'

// TODO Translation
function Step3RefineStructure() {
    const { t } = useTranslation();
    const iconClass = "w-10 h-10 mx-auto";
    const text = {
        pageTitle: t('Refine your structure'),
        card1Title: t('Refine your structure'),
        card1Text: t(`Redirect through more information pages`),
        card2Title: t('Resume structuring'),
        card2Text: t('Refine your structure within our integrated structuring tool'),
        card3Title: t('Link or Upload your structure'),
        card3Text: t('Use a piece of paper, a collaborative tool, a flipchart or a PPT document.'),
        card1Icon: <FontAwesomeIcon icon={faDiamondTurnRight} className={iconClass} />,
        card2Icon: <FontAwesomeIcon icon={faTree} className={iconClass} />,
        card3Icon: <FontAwesomeIcon icon={faLink} className={iconClass} />,
    }
    const { clusterId } = ':id' //TODO get real id
    const { problemId } = ':id' //TODO get real id

    const cardClassName = 'hover:bg-primary-100 pt-8 h-96';

    return (
        <div id="step-refineStructure">
            <FormContainer title={text.pageTitle} width="w-full lg:w-3/4">
                <CardGrid gridCol="grid-cols-1 sm:grid-cols-3">
                    <Link to={`/clusters/${clusterId}/problems/${problemId}/newProblemStructureGuided`}>
                        <Card id='card-startStructuredGuide'
                            className={cardClassName} justify="justify-top" title={text.card1Title} text={text.card1Text} icon={text.card1Icon}>
                            <img className="pt-8" src={teeTutorialImg} />
                        </Card>
                    </Link>
                    <Card id='card-createTree'
                        className={cardClassName} justify="justify-top" title={text.card2Title} text={text.card2Text} icon={text.card2Icon}>
                        <img className="pt-8" src={minimalTreeImg} />
                    </Card>
                    <Card id='card-uploadStructure'
                        className={cardClassName} justify="justify-top" title={text.card3Title} text={text.card3Text} icon={text.card3Icon}>
                        <img className="pt-8" src={externalDocumentImg} />
                    </Card>
                </CardGrid>
            </FormContainer>
        </div>
    );
}

export default Step3RefineStructure;