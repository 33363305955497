import { XIcon } from '@heroicons/react/outline'
import TextareaAutosize from 'react-textarea-autosize';
import SelectMenu from './SelectMenu';
import DatePicker from './DatePicker';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MarkdownArea from './MarkdownArea';

const tableInputClassNames = `border-1 border-white 
block sm:text-sm rounded-sm 
 resize-none`

function EditableRow({ id, noDelete, editActive, intitialMinRow = '1', maxRows = '100', noResize, translationFile, ...props }) {
    const rowData = (props.rowData || [{ id: 0, row: 1 }]);
    const rowRefs = useRef([]);
    const [minRows, setMinRows] = useState(intitialMinRow)
    const rowsCalculated = useRef(false);

    const setRowRef = (index) => (element) => {
        rowRefs.current[index] = element;
    };

    const intialArray = () => {
        let tempArray = [];
        for (let index = 0; index < rowData.length; index++) {
            tempArray.push({ id: index, row: 1 })
        }
        return tempArray;
    }

    const [heightArray, setHeightArray] = useState(intialArray);


    // resizing textareas in intial load
    useEffect(() => {
        if (!noResize) {
            requestAnimationFrame(() => {
                rowRefs.current.forEach((textarea) => {
                    if (textarea && textarea.scrollHeight) {
                        textarea.style.height = "auto";
                        textarea.style.height = `${textarea.scrollHeight}px`;
                    }
                });
            });
        }
    }, []);



    const heightHandler = (event, id) => {
        let tempArray = [...heightArray];
        let currRow = rowCalc(event);
        tempArray[id].row = currRow;
        setHeightArray(tempArray);
        let min = Math.max(...tempArray.map(row => row.row));
        setMinRows(min);
    }

    const rowCalc = (height) => {
        return Math.round((height - 38) / 20) + 1;
    }


    // sends event data to parent, (not just event!)
    const changeHandler = (eventData, name, id) => {
        props.changeHandler(props.rowIndex, name, eventData, id);
        setMinRows(1);
    }

    // select needs to be handled differently because the data isn't stored in event.target.value but just inside 'event' 
    const selectHandler = (data, name, id) => {
        props.changeHandler(props.rowIndex, name, data, id);
    }

    return (
        <tr key={props.rowId} id={"row-" + props.rowId} className="border-b border-gray-200 table-fixed w-full">
            {rowData.map((item, index) => (
                // ${item.type === "selectMenu" && "pt-8px"}
                <td className={`align-top ${item.style} `}
                    key={item.id}>
                    {item.type === "area" && (
                        editActive ? (
                            <TextareaAutosize
                                ref={setRowRef(index)}
                                readOnly={!editActive}
                                cacheMeasurements
                                id={`${id + item.id}`}
                                className={`${tableInputClassNames} ${item.innerStyle || 'w-full'} 
                                ${editActive
                                        ? 'focus:ring-primary-500 focus:border-primary-500 focus:border-1 hover:border-primary-300'
                                        : 'cursor-auto border focus:ring-white focus:border-white'}`}
                                value={item.value || ''}
                                placeholder="..."
                                minRows={minRows}
                                maxRows={maxRows}
                                onChange={(event) => changeHandler(event.target.value, item.name, item.id)}
                                onHeightChange={(e) => { heightHandler(e, index); }}
                            />
                        ) : (
                            <MarkdownArea editMode={false} value={item.value} border={false}/>
                        )
                    )}

                    {item.type === "selectMenu" &&
                        <SelectMenu
                            itemList={item.itemList}
                            selected={item.value || (item.itemList && item.itemList[0]) || 'undefined List'} // sets the first value as default value if no item is selected
                            onChange={(event) => selectHandler(event, item.name, item.id)}
                            style={item.style || 'w-full'}
                            translationFile={translationFile}
                            editActive={editActive}
                        />

                    }
                    {item.type === 'checkbox' &&
                        <input
                            name={item.name}
                            type='checkbox'
                            checked={item.value}
                            disabled={!editActive}
                            onChange={(e) => changeHandler(e.target.checked, item.name, item.id)}
                            className={`rounded-sm m-auto ${editActive ? 'focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded' : 'bg-gray-200 border-gray-400'} `}

                        />
                    }
                    {item.type === 'date' &&
                        <DatePicker
                            className={`${item.style} mt-1`}
                            value={item.value}
                            onChange={(date) => changeHandler(date, item.name, item.id)}
                            viewOnly={!editActive}
                        />
                    }
                    {item.type === 'other' &&
                        item.item
                    }
                    {/* Default Case: Fallback */}
                    {!['area', 'markdown', 'selectMenu', 'checkbox', 'date', 'other'].includes(item.type) &&
                        <input
                            type="text"
                            value={item.value || ''}
                            readOnly={!editActive}
                            placeholder="..."
                            className={`${tableInputClassNames} ${item.innerStyle || 'w-full'} 
                                    ${editActive
                                    ? 'focus:ring-primary-500 focus:border-primary-500 focus:border-1 hover:border-primary-300'
                                    : 'cursor-auto border focus:ring-white focus:border-white'}`}
                            onChange={(event) => changeHandler(event.target.value, item.name, item.id)}
                        />
                    }

                </td>
            ))}
            {!noDelete &&
                <>
                    {(props.oneRow || !editActive) ?
                        <td className='w-16'></td>
                        : <td className='w-16 group text-center cursor-pointer px-2' onClick={props.delete}>
                            <XIcon className='w-5 h-5 m-auto group-hover:text-primary-600 text-gray-500 transform group-hover:scale-125' />
                        </td>}
                </>
            }
        </tr>
    );
}

EditableRow.propTypes = {
    id: PropTypes.string,
    noDelete: PropTypes.bool,
    editActive: PropTypes.bool,
    intitialMinRow: PropTypes.string,
    maxRows: PropTypes.string,
    noResize: PropTypes.bool,
    translationFile: PropTypes.string,
    rowData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            type: PropTypes.oneOf(['area', 'selectMenu', 'checkbox', 'date', 'other']),
            value: PropTypes.any,
            name: PropTypes.string,
            style: PropTypes.string,
            innerStyle: PropTypes.string,
            itemList: PropTypes.array,
            item: PropTypes.node,
        })
    ).isRequired,
    rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changeHandler: PropTypes.func,
    delete: PropTypes.func,
    oneRow: PropTypes.bool,
};



export default EditableRow;