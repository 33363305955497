import React from 'react';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/outline'

function DeleteTableIcon({ deleteHandler }) {
    return (
        <div className=' group text-center cursor-pointer absolute top-[18px] right-2' onClick={deleteHandler}>
            <XIcon className='w-5 h-5 m-auto group-hover:text-primary-600 text-gray-500 group-hover:scale-125' />
        </div>
    );
}

DeleteTableIcon.propTypes = {
    deleteHandler: PropTypes.func.isRequired
};

export default DeleteTableIcon;