import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import FormContainer from 'components/FormContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverviewRowModule from './OverviewRowModule';
import CreateCommunityContext from '../context/CommunityContext';
import CommunityMultiCheck from '../Steps/CommunityMultiCheck';

import { faEdit, faGear, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import { getCommunityById } from 'actions/community.get.actions';
import { updateCommunityById } from 'actions/community.update.actions';
import { deleteCommunityById } from 'actions/community.delete.actions';
import { listUserCommunities } from 'actions/communities.list.actions';

import SimpleGroupHover from 'components/SimpleGroupHover';
import Modal from 'components/Modal';
import Input from 'components/InputElement';
import MultiSelectBoxMultiLevel from "components/MultiSelectBoxMultiLevel";

import { updateCheckitemState } from "utils/update-checkitem-state"
import { flatArrayForSelected } from 'utils/flat-array-for-selected';
import { changeSelectedIdList as changeIdList } from "utils/change-selected-id-list";
import { filterValueChains } from 'pages/UserProfilePage/ProfileForm';
import DisplayTagList from "components/DisplayTagList";

import ProblemSettingsModal from './ProblemSettingsModal';

function CommunityOverview({
    title,
    editOnly,
    defaultOpen,
    handleValidation,
    isAdmin,
    id,
    pass,
    config,
    getCommunityById,
    updateCommunityById,
    deleteCommunityById,
    listUserCommunities
}) {
    const history = useHistory();
    const shortId = (id && id.split('-')[0]) || null

    const newCommunity = (editOnly || false); // true for new Community, to hide share/edit button
    const [editModeActive, setEditModeActive] = useState(newCommunity ? true : false); // switch for Product 'Community Definition' 

    const { t } = useTranslation(['common', 'community', 'user']);
    const text = {
        header: (title || ""),
        visibility: t('1-ov-visib', "Visibility", { ns: 'community' }),
        title: t('1-ps-title', "Title", { ns: 'community' }),
        industry: t('area0', 'Industries', { ns: 'community' }),
        segment: t('area1', 'Market Segments', { ns: 'community' }),
        valueChain: t('area2', 'Value Chain Segments', { ns: 'community' }),
        tags: t('1-ps-tags', 'Tags', { ns: 'community' }),
        inValidMessage: t('required-hoverInfo1', 'Please fill in all required.'),
        deleteCommunity: t('co-del', 'Delete Community', { ns: 'community' }),
        deleteText1: t('co-del-text1', 'Do you really want to delete the community ', { ns: 'community' }),
        deleteText2: t('co-del-text2', ' and all the content belonging to it?', { ns: 'community' }),
        cancel: t('Cancel', { ns: 'common' }),
    }

    const ctx = useContext(CreateCommunityContext);

    const { communityId } = useParams();

    const settings = [
        { text: t('New Topic'), modal: '#problemSettings' }
    ]

    const editActiveHandler = () => {
        setEditModeActive(!editModeActive);
        // getCommunityById(communityId);
    }
    const [industryList, setIndustryList] = useState([])
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);

    // updates internal segmentList with the checked objects
    useEffect(() => {
        if (ctx.segmentListData?.length > 0) {
            const newSegmentList = ctx.segmentListData.map(item => updateCheckitemState(item, ctx.data.segmentSelected || []));
            setSegmentList(newSegmentList);
            setIndustryList(newSegmentList.filter(item => item?.checked).map(item => item?.industry));
        }
    }, [ctx.segmentListData, ctx.data.segmentSelected]);

    // updates internal valueChainList with the checked objects
    // filter Value Chains either based on selected parents in Segments or previously selected Value Chains 
    useEffect(() => {
        let filteredList = filterValueChains(ctx.valueChainListData, ctx.data.valueChainSelected, industryList);
        const newValueList = filteredList.map(item => updateCheckitemState(item, ctx.data.valueChainSelected || []));
        setValueChainList(newValueList);

    }, [ctx.valueChainListData, ctx.data.valueChainSelected, industryList]);

    const [isValid, setIsValid] = useState({
        title: true
    });

    const [documentValid, setDocumentValid] = useState(null)

    useEffect(() => {
        if (isValid.title) {
            setDocumentValid(true)
            handleValidation && handleValidation(true)
        } else {
            setDocumentValid(false)
            handleValidation && handleValidation(false)
        }
    }, [handleValidation, isValid]);

    // updates the context data and the validation for the fields 
    const changeAndUpdateValidation = (event, fieldName) => {
        ctx.itemHandler(fieldName, event.target.value)
        setIsValid({ ...isValid, [fieldName]: event.target.value?.length > 0 })
    }

    const handleSave = (e) => {
        e.preventDefault()
        let data = ctx.data
        updateCommunityById(communityId, data)
        setEditModeActive(!editModeActive)
    };

    const handleSaveWithoutModeSwitch = (e) => {
        e.preventDefault()
        let data = ctx.data
        updateCommunityById(communityId, data)
    };

    const handleDelete = (e) => {
        e.preventDefault()
        deleteCommunityById(communityId).then(() => {
            sessionStorage.setItem('community', null)
            listUserCommunities()
        })
        history.push(`/home`)
    };

    // updates formData with new Checked id 
    const changeSegmentList = (item, checked) => {
        ctx.itemHandler('segmentSelected', changeIdList(ctx.data.segmentSelected, ctx.segmentListData, item, checked))
    };

    const changeValueChainList = (item, checked) => {
        ctx.itemHandler('valueChainSelected', changeIdList(ctx.data.valueChainSelected, ctx.valueChainListData, item, checked))
    };

    const iconClass = 'h-6 cursor-pointer'
    // const hoverInfoClass = 'absolute top-8 scale-0 transition-all rounded bg-primary-100 px-2 py-1 text-sm text-primary-700 group-hover:scale-100 border border-primary-200 z-20'
    const iconColor = 'text-primary-500 hover:text-primary-700'

    return (
        <div>
            <Modal
                id='modalCommunityDelete'
                title={text.deleteCommunity}
                onSave={handleDelete}
                saveText={text.deleteCommunity}
                saveBg='bg-red-700'
                closeText={text.cancel}
                backdrop='true'>
                {text.deleteText1} <span className='font-bold'>{ctx.data.title} </span> {text.deleteText2}
            </Modal>
            <ProblemSettingsModal id='problemSettings' ctx={ctx} config={config} handleSave={handleSaveWithoutModeSwitch} />
            <div id='communityOverview'
                style={{ width: '65%' }}
                className='transition-all duration-300 ease-in-out'>
                {!newCommunity && <div id='communityTitle' className='float-left ml-4'>
                    <p className='font-bold text-lg'>{ctx.data.title}</p>
                    {isAdmin && !newCommunity && <p>Passcode: {shortId}\{pass}</p>}
                    {/* Todo translation */}
                    {ctx.data.createdBy && ctx.data.createdBy.userId &&
                        <Link to={`/profiles/${ctx.data.createdBy.userId}`}>
                            <p className='text-gray-600'>{t('Created by')} <span className='font-bold'>{ctx.data.createdBy && ctx.data.createdBy.firstName} {ctx.data.createdBy && ctx.data.createdBy.lastName}</span></p>
                        </Link>
                    }
                </div>}
                <div id='buttonArea' className='flex float-right my-0 space-x-6'>
                    {isAdmin && !newCommunity &&
                        <>
                            {/* Save / Edit Button */}
                            <SimpleGroupHover
                                color={!documentValid && 'red'}
                                position={"top-8"}
                                text={editModeActive ? (documentValid ? t('Save') : text.inValidMessage) : t('Edit')}
                                className={!documentValid && 'w-[200px]'}
                            >
                                <FontAwesomeIcon
                                    id='communityDefinition-toggleEditActive-btn'
                                    className={`h-6
                                    ${documentValid ? iconColor : 'text-gray-200 cursor-not-allowed'}`}
                                    onClick={editModeActive ? (documentValid ? handleSave : null) : editActiveHandler}
                                    icon={editModeActive ? faSave : faEdit} />
                            </SimpleGroupHover>
                            {/* Cancel */}
                            {editModeActive &&
                                <span className='group relative'>
                                    <SimpleGroupHover
                                        position={"top-7"}
                                        text={t('Cancel')}>
                                        <FontAwesomeIcon
                                            id='communityDefinition-toggleCancelActive-btn'
                                            className={`${iconClass} ${iconColor}`}
                                            onClick={editActiveHandler}
                                            icon={faTimes}
                                        />
                                    </SimpleGroupHover>
                                </span>
                            }
                            {/* Settings */}
                            <span
                                data-bs-toggle='modal'
                                data-bs-target='#problemSettings'>
                                <FontAwesomeIcon
                                    id='communitySettings'
                                    className={`${iconClass} ${iconColor}`}
                                    icon={faGear}
                                />
                            </span>
                            {/* Delete */}
                            <span className='group relative'
                                data-bs-toggle='modal'
                                data-bs-target='#modalCommunityDelete'>
                                <SimpleGroupHover
                                    text={t('Delete')}
                                    position={'top-7'}>
                                    <FontAwesomeIcon
                                        id='communityDefinition-delete-btn'
                                        className={`${iconClass} text-red-700`}
                                        icon={faTrash}
                                    />
                                </SimpleGroupHover>
                            </span>
                        </>
                    }
                </div>

                <FormContainer title={text.header} width="w-full">
                    <div className="">
                        {editModeActive &&
                            <OverviewRowModule index='title'
                                label={text.title}
                                value={ctx.data.title}
                                onChange={(e) => changeAndUpdateValidation(e, 'title')}
                                type="area"
                                editActive={editModeActive}
                                required
                                className={'font-bold'}
                            />}
                        <p className='text-black'>{documentValid}</p>
                        <OverviewRowModule index='segments'
                            label={text.segment}
                            type="other" editActive={editModeActive}
                        >
                            { editModeActive ? 
                            <Input
                                id={'interestsSegment'}
                                label={null}
                                inputType={'other'}>
                                <MultiSelectBoxMultiLevel
                                    list={segmentList}
                                    updateList={changeSegmentList}
                                    name={'interestsSegment'}
                                />
                            </Input> 
                            : 
                            <DisplayTagList
                                list={flatArrayForSelected(segmentList, ctx.data.segmentSelected)}
                                bgColor={'bg-sky-100'}
                                borderColor={'border-sky-200'} 
                                />
                            }
                        </OverviewRowModule>
                        <OverviewRowModule index='valueChains'
                            label={text.valueChain}
                            type="other" editActive={editModeActive}
                        >
                            { editModeActive ? 
                            <Input
                                id={'interestsValueChain'}
                                label={null}
                                inputType={'other'}>
                                <MultiSelectBoxMultiLevel
                                    list={valueChainList}
                                    updateList={changeValueChainList}
                                    name={'interestsValueChain'}
                                />
                            </Input> 
                            : 
                            <DisplayTagList
                                list={flatArrayForSelected(valueChainList, ctx.data.valueChainSelected)}
                                bgColor={'bg-sky-100'}
                                borderColor={'border-sky-200'} 
                                />
                            }
                        </OverviewRowModule>
                    </div>
                </FormContainer>
            </div>
        </div>
    );
}

CommunityOverview.propTypes = {
    getCommunityById: PropTypes.func.isRequired,
    updateCommunityById: PropTypes.func.isRequired,
    deleteCommunityById: PropTypes.func.isRequired,
    listUserCommunities: PropTypes.func.isRequired,
};

export default connect(null, {
    getCommunityById,
    updateCommunityById,
    deleteCommunityById,
    listUserCommunities
})(CommunityOverview);