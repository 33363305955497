import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import EditableTable from 'components/EditableTable';
import EditableRow from 'components/EditableRow';

const TaskTable = ({ tasks, editActive, updateTask, deleteTask, tableId, addTask }) => {
    const { t } = useTranslation(['minutes'])

    // TODO add translation and key
    const tableHeader = [
        { name: 'task', text: t('ta', 'Task') },
        { name: 'responsible', text: t('re', 'Responsible') },
        { name: 'deadline', text: t('de', 'Deadline') },
        { name: 'status', text: t('st', 'Status') },
        { name: 'comment', text: t('co', 'Comment') }
    ]

    const changeHandler = (taskId, field, value) => {
        updateTask(tableId, taskId, field, value);
    }

    const newRowHandler = () => {
        addTask(tableId);
    }

    return (
        <div id='taskTable' className={'ml-14 -mt-[16px]'}>
            <EditableTable tableHeader={tableHeader} editActive={editActive} addRow={newRowHandler} padding={'py-1 px-2'} rounded={'rounded-none'}>
                {tasks.map((task) => {
                    const rowData = [
                        { id: 0, name: 'task', value: task.task, type: 'area' },
                        { id: 1, name: 'responsible', value: task.responsible, type: 'area' },
                        { id: 2, name: 'deadline', value: task.deadline, type: 'date', style: "w-[100px]" },
                        { id: 3, name: 'status', value: task.status, type: 'selectMenu', style: "w-36", itemList: ["OPEN", "IMPLEMENTED"] },
                        { id: 4, name: 'comment', value: task.comment, type: 'area' },
                    ];
                    return (
                        <EditableRow
                            id={'TaskTable' + task.id}
                            key={task.id}
                            editActive={editActive}
                            rowData={rowData}
                            rowIndex={task.id}
                            changeHandler={(id, name, value) => changeHandler(id, name, value)}
                            delete={() => deleteTask(tableId, task.id)}
                            translationFile='minutes'   
                        />
                    );
                })}
            </EditableTable>
        </div>
    );
};

TaskTable.propTypes = {
    tasks: PropTypes.array.isRequired,
    editActive: PropTypes.bool,
    updateTask: PropTypes.func,
    deleteTask: PropTypes.func,
    tableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addTask: PropTypes.func,
};

export default TaskTable;