import { clusterActionTypes as types } from 'constants/cluster.action.types';
  
const initialState = (window.Cypress && window.initialState && window.initialState.clusterReducer) || {};

export function clusterReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.LIST_CLUSTERS_REQUEST:
    case types.GET_PROJECT_REQUEST:
    case types.CREATE_PROJECT_REQUEST:
    case types.UPDATE_PROJECT_REQUEST:
    case types.DELETE_PROJECT_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.LIST_CLUSTERS_SUCCESS:
      return {
        ...state,
        clusters: payload
    };
    case types.GET_PROJECT_SUCCESS:
    case types.CREATE_PROJECT_SUCCESS:
    case types.UPDATE_PROJECT_SUCCESS: 
      return {
        ...state,
        cluster: payload
    };
    case types.DELETE_PROJECT_SUCCESS:
      return {
          ...payload
      };
    case types.LIST_CLUSTERS_FAILURE:
    case types.GET_PROJECT_FAILURE:
    case types.CREATE_PROJECT_FAILURE:
    case types.DELETE_PROJECT_FAILURE:
    default:
      return state;
  }
}
  