import React from 'react';
import PropTypes from 'prop-types';

/**
 * Standart label component
 */
function Label({ className, children, htmlFor }) {
    return (
        <label
            className={`block mb-1 text-gray-600 text-base font-medium select-none ${className}`}
            htmlFor={htmlFor}>
            {children}
        </label>
    );
}

Label.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    htmlFor: PropTypes.string,
};

export default Label;