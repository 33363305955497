import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";

import Button from 'components/Button';
import CardGrid from 'components/CardGrid';

import ProblemCard from './ProblemCard';
import { updateVoteById } from 'actions/voting.update.actions';
import { listProblems } from 'actions/problems.list.actions';
import { updateProblemById } from 'actions/problem.update.actions';


// TODO Translations
function ProblemsList({
    profile,
    problemsList,
    clickTag,
    updateVoteById,
    listProblems,
    updateProblemById
}) {

    const { t } = useTranslation(['common']);
    const { clusterId } = useParams();

    const [problemsSorted, setProblemsSorted] = useState([]);

    useEffect(() => {
        if (problemsList) {
            setProblemsSorted(problemsList)
        }
    }, [problemsList]);

    const upVote = (problem) => {
        const userVote = problem.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote + 1 <= 1 ) {
            updateVoteById(problem.id, userVote + 1).then(() => {
                listProblems(clusterId)
            })
        }
    }

    const downVote = (problem) => {
        const userVote = problem.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote - 1 >= -1 ) {
            updateVoteById(problem.id, userVote - 1).then(() => {
                listProblems(clusterId)
            })
        }
    }

    return (
        <div id='problemsListPage' className=''>
            <div className='space-y-4'>
                { clusterId !== '00000000-0000-0000-0000-000000000000' &&
                <div id='newTopicButton' className='flex flex-row space-x-2'>
                    <Link
                        to={`/clusters/${clusterId}/topics/new`}>
                        <Button
                            id='newProblemBtn'
                            bg="bg-green-600"
                            hover="hover:bg-green-700"
                            focus="'focus:ring-green-500'"
                        >
                            <PlusIcon className="ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            {t('New Topic')}
                        </Button>
                    </Link>
                </div>
                }
                {/* Problem Grid */}
                <CardGrid className={'items-start'} gridCol={'grid-cols-1 md:grid-cols-2'}>
                    {problemsSorted.map((problem, index) => (
                        <ProblemCard
                            key={index}
                            problem={problem}
                            userVotes={problem.userVotes}
                            count={problem.userVotes.reduce((acc, obj) => (acc + obj.vote), 0)}
                            showVoting={clusterId === '00000000-0000-0000-0000-000000000000' ? false : true}
                            upVote={() => upVote(problem)}
                            downVote={() => downVote(problem)}
                            upVoteClicked={problem.userVotes?.find(obj => obj.userId === profile.userId)?.vote === 1}
                            downVoteClicked={problem.userVotes?.find(obj => obj.userId === profile.userId)?.vote === -1}
                            clickTag={clickTag}
                            updateProblemById={updateProblemById}
                        />
                    ))}
                </CardGrid>
            </div>
        </div>
    );
}

updateVoteById.propTypes = {
    profile: PropTypes.array.isRequired,
    updateVoteById: PropTypes.func.isRequired,
    listProblems: PropTypes.func.isRequired,
    updateProblemById: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { updateVoteById, listProblems, updateProblemById })(ProblemsList);