import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Menu } from '@headlessui/react'
import MenuItemWrapper from 'components/Menu/MenuItemWrapper';
import MenuItem from 'components/Menu/MenuItem';
import { BellIcon } from '@heroicons/react/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { updateUserAlerts } from 'actions/user-alerts.update.actions';
import { listUserCommunities } from 'actions/communities.list.actions';
import NotificationItem from 'components/NotificationItem';
import { useTranslation } from "react-i18next";

// import { loadingActions } from 'actions/loading.actions';

const menuButton = 'bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600'

function Notifications({ userAlerts, updateUserAlerts, listUserCommunities }) {
    const { t } = useTranslation(['common'])
    const [notificationList, setNotificationList] = useState([])
    const [newNotificationCount, setNewNotificationCount] = useState()

    useEffect(() => {
        if (userAlerts?.userAlerts) {
            let list = sortList(userAlerts.userAlerts)
            setNotificationList(list)
            setNewNotificationCount(list.filter(notification => notification.isActive)?.length)
        }
    }, [userAlerts?.userAlerts])

    const handleNotificationClick = (notificationId) => {
        const updatedAlerts = notificationList.map(alert => {
            if (alert.id === notificationId) {
                return { ...alert, isActive: false };
            }
            return alert;
        });
        setNotificationList(sortList(updatedAlerts))
        setNewNotificationCount(updatedAlerts.filter(notification => notification.isActive)?.length)
        const updatedUserAlerts = { ...userAlerts, userAlerts: updatedAlerts };

        // fire and forget
        updateUserAlerts(updatedUserAlerts).then(() => {
            listUserCommunities();
        })
        // dispatch(loadingActions.finish()) // this is important to prevent infinite spinning
    }

    function sortList(list) {
        return list.sort((a, b) => new Date(b.createdTs) - new Date(a.createdTs));
    }

    return (
        <>
            <Menu.Button className={menuButton} id='alertButton'>
                <a className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                </a>
                {newNotificationCount > 0 &&
                    <span id='notificationCountBubble'
                        className="absolute -top-1 -right-2 bg-primary-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                        {newNotificationCount}
                    </span>}
            </Menu.Button>
            <MenuItemWrapper width={'w-72'}>
                {(notificationList?.length > 0) ?
                    notificationList.map((item) => {
                        return (
                            <div key={item.id} className="flex items-center justify-between px-2 hover:bg-gray-100" data-test="notification-item">
                                <div className="flex-1">
                                    <MenuItem to={`/clusters/${item.clusterId}/topics/${item.problemId}`} padding={'py-2'} onClick={() => handleNotificationClick(item.id)}>
                                        <NotificationItem item={item} />
                                    </MenuItem>
                                </div>
                                <div id='newNotificationCircle'>
                                    <span className='flex'>
                                        {item.isActive &&
                                            <FontAwesomeIcon className="h-2 text-primary-600" icon={faCircle} />}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                    :
                    <p className='pl-2 py-4 text-gray-600 italic'>{t('not-1', 'No Notifications')}</p>
                }
            </MenuItemWrapper >
        </>
    );
}

Notifications.propTypes = {
    userAlerts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    updateUserAlerts: PropTypes.func.isRequired,
    listUserCommunities: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    userAlerts: state.userAlertReducer.userAlerts
});

export default connect(mapStateToProps, { updateUserAlerts, listUserCommunities })(Notifications);