import PropTypes from 'prop-types';

function updateNestedItem(setState, parentId, childKey, childId, field, value) {
    setState(prevState => {
        return prevState.map(parent =>
            parent.id === parentId
                ? {
                    ...parent,
                    [childKey]: parent[childKey].map(child =>
                        child.id === childId ? { ...child, [field]: value } : child
                    )
                }
                : parent
        );
    });
};

updateNestedItem.propTypes = {
    setState: PropTypes.func.isRequired,
    parentId: PropTypes.string.isRequired,
    childKey: PropTypes.string.isRequired,
    childId: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
};

export { updateNestedItem }
