import Container from "../Container";
import InfoBanner from "../InfoBanner";
import { AdminSection } from "./AdminSection";
import { FilterSection } from "./FilterSection";
import InfoSection from "./InfoSection";
import PropTypes from 'prop-types';

function GridHeader({ children, bannerText, topicInfo, owner, teamUserList, filterProps }) {

    return (
        <Container>
            {bannerText &&
                <div className="-mt-4 mb-3">
                    <InfoBanner>
                        {bannerText}
                    </InfoBanner>
                </div>}
            <div className="flex flex-row justify-between mb-2">
                {/* Info Section */}
                <InfoSection topicInfo={topicInfo} owner={owner} />
                {/* Filter Section */}
                <div className="flex items-end justify-end space-x-4">
                    <FilterSection filterProps={filterProps} />
                    {/* display Admin section if there is a userlist provided */}
                    {teamUserList && <AdminSection teamUserList={teamUserList} showclusterRoles={true}/>} 
                </div>
            </div>
            {children}
        </Container>
    );
}

GridHeader.propTypes = {
    children: PropTypes.node,
    bannerText: PropTypes.string,
    topicInfo: PropTypes.object,
    owner: PropTypes.object,
    teamUserList: PropTypes.array,
    filterProps: PropTypes.object.isRequired,
}

export default GridHeader;
