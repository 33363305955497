import { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";

import Tooltip from 'components/Tooltip';
import LabelWithSmarttips from "components/LabelWithSmarttips";

import TopicContext from 'pages/CreateTopicPage/context/TopicContext';
import CompactMetricStatGrid from 'components/CompactMetricStatGrid';
import usePercentage from 'hooks/usePercentage';
import CollapsableDiv from 'components/CollapsableDiv';

const topBorder = 'border-t-2 mt-1 pt-2 border-gray-200'

function CoreMetricsForm({ keyResultIndex, isOverview, editActive, ...props }) {
    const ctx = useContext(TopicContext);
    const thisObject = ctx.data?.keyResults[keyResultIndex]?.coreMetric;
    const { t } = useTranslation('problemDefinition');
    const text = {
        headline: t('1-pm-title', "Core Metrics"),
        defineBaseline: t('1-pm-prompt1', "Define the baseline for your quantitative key result"),
        denominationLabel: t('1-pm-label1', "Denomination and Date"),
        denominationPlaceholder: "...",
        valueLabel: t('1-pm-label2', "Baseline"),
        valuePlaceholder: 0,
        baselineTooltip: t('1-pm-tooltip1',
            `Example:\n\n Denomination and date:\n Greenhouse gas emissions as per December 31st, 2020\n
            Value and unit: 2000 tons`), //TODO link to plot
        // baselineTooltipLink: t('1-pm-tooltip1-ref', 'Show Racecourse plot'),
        baselineSmartTip: [
            {
                title: t('1-pm-1t', "Date-based"),
                description: t('1-pm-1d', "The baseline needs to be set at a specific point in time")
            },
            {
                title: t('1-pm-2t', "Measurable"),
                description: t('1-pm-2d', "The baseline needs to have a value and a unit of measure")
            },
            {
                title: t('1-pm-3t', "Solid"),
                description: t('1-pm-3d', "The baseline should not be subject to debate and discord")
            },
            {
                title: t('1-pm-4t', "Linked"),
                description: t('1-pm-4d', "Ideally in the same denomination and unit as the most relevant organizational goal, or at least bridgeable")
            },
        ],
        unitLabel: t('1-pm-label3', "Unit"),
        provideCoreTarget: t("1-pm-prompt2", "Provide a quantitative key result target"),
        targetTooltip: t('1-pm-tooltip2', 'Even if your problem is multidimensional, we still encourage you to pick one primary quantitative key result that will accompany your team through the entire problem solving journey. You can also set a secondary, even a tertiary one'),
        targetSmartTip: [
            { title: t('1-pm-5t', 'Quantitative key result'), description: t('1-pm-5d', 'One, ideally the most important, quantifiable key result') },
            { title: t('1-pm-6t', 'Contribution'), description: t('1-pm-6d', 'Contributing transparently to your broader organizational goals') },
            { title: t('1-pm-7t', 'Consistency'), description: t('1-pm-7d', 'Same denomination and unit as baseline') },
        ],
        targetLabel: t('1-pm-label4', "Target"),
        deltaTarget: t('1-pm-label5', "Delta"),
        organizationalPrompt: t('1-pm-prompt3', 'Assign the organizational goal to which the primary quantitative Key Result contributes'),
        organizationTips: [
            { title: t("1-pm-8t", "Select goal"), description: t("1-pm-8d", "E.g., the goal “grow to become No. 2 in the market” if your primary quantitative key result is growth-related. The goals might be defined centrally by your administrator.") },
            { title: t("1-pm-9t", "Estimate contribution"), description: t("1-pm-9d", "Estimate which part of your key result (or the delta) will contribute to the identified goal – often, the contribution will be 100%, but this might vary. Example: “We want to increase gross profit by 50 USDmn, and 90% of that will contribute to our organization’s profit goal. We need to exclude 10% because of definition issues”") },
            { title: t("1-pm-10t", "Identify spillovers"), description: t("1-pm-10d", "Evaluate whether you should identify a secondary quantitative result that then contributes to another organizational goal (e.g., a key result aimed at profit within a growth initiative)") },
        ],
        goalLabel: t("1-pm-goalLabel", "Company Goal"),
        goalPercentageLabel: t("1-pm-percentage", "Percentage"),
        goalTooltip: t("1-pm-tooltip3", `E.g.:\n If you want to accomplish a growth result of 1000 EUR, then the contribution to the organization goal “Growth” is often 100% of 1000 EUR.  
        You can set a secondary quantitative result if solving your problem contributes to more than one goal 
        `),
        metricLabel: t("1_PM_metrics", "Metrics")
    }
    const tooltipBreakpoint = props.guideActive ? "none" : "xl";
    const tooltipClassname = "mt-2";
    const [basePercentage, setBasePercentage] = useState(thisObject?.baselineValue != 0 ? 100 : 0)

    const changeHandler = (e, name) => {
        const newValue = e.target.value;
        const updatedCoreMetric = { ...ctx.data.keyResults[keyResultIndex].coreMetric, [name]: newValue };
        // Use rowHandler to update the coreMetric inside keyResults
        ctx.rowHandler('keyResults', 'coreMetric', keyResultIndex, updatedCoreMetric);
    };

    const handleBaseValueChange = (e) => {
        const newBaseValue = e.target.value;
        // Update baselineValue, set target to baselineValue, and deltaTarget to 0
        const updatedCoreMetric = {
            ...ctx.data.keyResults[keyResultIndex].coreMetric,
            baselineValue: newBaseValue,
            target: newBaseValue,  // Set target same as baseline initially
            deltaTarget: 0,        // Reset deltaTarget to 0
        };
        // handle percentage for overview
        if (e.target.value === (null || undefined || NaN || 0 || '0')) {
            setBasePercentage(0)
        } else {
            setBasePercentage(100)
        }
        ctx.rowHandler('keyResults', 'coreMetric', keyResultIndex, updatedCoreMetric);
    };

    const handleTargetChange = (e) => {
        const newTargetValue = e.target.value;
        const baselineValue = ctx.data.keyResults[keyResultIndex].coreMetric.baselineValue || 0;

        // Calculate deltaTarget as target - baselineValue
        const updatedCoreMetric = {
            ...ctx.data.keyResults[keyResultIndex].coreMetric,
            target: newTargetValue,
            deltaTarget: parseInt(newTargetValue) - parseInt(baselineValue),
        };

        ctx.rowHandler('keyResults', 'coreMetric', keyResultIndex, updatedCoreMetric);
    };

    // Stats for Overview Grid display
    const stats = [
        { name: text.valueLabel, value: thisObject?.baselineValue, percentage: basePercentage, onChange: handleBaseValueChange, },
        { name: text.deltaTarget, value: thisObject?.deltaTarget, percentage: usePercentage(thisObject?.baselineValue, thisObject?.deltaTarget), readOnly: true },
        { name: text.targetLabel, value: thisObject?.target, percentage: usePercentage(thisObject?.baselineValue, thisObject?.target), onChange: handleTargetChange, },
    ];
    // for Overview Grid display
    const companyGoal = {
        name: text.goalLabel,
        value: thisObject?.companyGoal,
        percentage: thisObject?.companyGoalPercent,
        onChange: (e) => changeHandler(e, 'companyGoal'),
        onPercentageChange: (e) => changeHandler(e, 'companyGoalPercent')
    }

    return (
        <div id='coreMetricSubForm'>
            {/* Define Baseline */}
            <CollapsableDiv header={text.metricLabel}>
                <div className=''>
                    {!isOverview
                        && <>
                            <Tooltip
                                mobileClassName={tooltipClassname}
                                breakpoint={tooltipBreakpoint} content={text.baselineTooltip} link={text.baselineTooltipLink} />
                            <LabelWithSmarttips id="baselineTips" title={text.defineBaseline} tips={text.baselineSmartTip} />
                        </>}
                    {/* Denomination*/}
                    <div className="space-y-2">
                        {!isOverview && <div className={isOverview ? 'mt-2' : topBorder}>
                            <label htmlFor="denomination" className="block text-sm font-medium text-gray-700">
                                {text.denominationLabel}
                            </label>
                            <div className="mt-1">
                                <input
                                    id="denominationInput"
                                    type="text"
                                    name="denomination"
                                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder={text.denominationPlaceholder}
                                    value={thisObject?.baselineDescription}
                                    onChange={(e) => changeHandler(e, 'baselineDescription')}
                                />
                            </div>
                        </div>}
                        {/* Value & Unit */}
                        {!isOverview &&
                            <div className="flex space-x-4">
                                <div className="w-24">
                                    <label htmlFor="denominationValue" className="block text-sm font-medium text-gray-700">
                                        {text.valueLabel}
                                    </label>
                                    <div className="">
                                        <input
                                            id="denominationValueInput"
                                            type="number"
                                            min={0}
                                            name="denominationValue"
                                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder={text.valuePlaceholder}
                                            value={thisObject?.baselineValue}
                                            onChange={handleBaseValueChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-24">
                                    <label htmlFor="denominationUnit" className="block text-sm font-medium text-gray-700">
                                        {text.unitLabel}
                                    </label>
                                    <div className="">
                                        <input
                                            id="denominationUnitInput"
                                            type="text"
                                            name="denominationUnit"
                                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder={"..."}
                                            value={thisObject?.unit}
                                            maxLength="10"
                                            onChange={(e) => changeHandler(e, 'unit')}
                                        />
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                {/* Target Value, Company Goal, Percentage Input */}
                {!isOverview &&
                    <>
                        {/* Target Value */}
                        <div className=''>
                            <Tooltip content={text.targetTooltip} mobileClassName={tooltipClassname} breakpoint={tooltipBreakpoint} />
                            <LabelWithSmarttips id="targetTips" title={text.provideCoreTarget} tips={text.targetSmartTip} />
                            <div className={`${topBorder} flex space-x-4`}>
                                <div className="w-40">
                                    <label htmlFor="targetValue" className="block text-sm font-medium text-gray-700">
                                        {text.targetLabel}
                                    </label>
                                    <div className="flex items-end">
                                        <input
                                            id="targetValueInput"
                                            type="number"
                                            name="targetValue"
                                            className="shadow-sm 
                                                focus:ring-primary-500 focus:border-primary-500 
                                                w-24
                                                mr-1 
                                                sm:text-sm border-gray-300 rounded-md"
                                            placeholder={text.valuePlaceholder}
                                            value={thisObject?.target}
                                            onChange={handleTargetChange}
                                        />
                                        <label className="text-sm font-medium text-gray-700 pb-2">{thisObject?.unit}</label>
                                    </div>

                                </div>
                                <div className="w-40">
                                    <label htmlFor="deltaValue" className="whitespace-nowrap block text-sm font-medium text-gray-700">
                                        {text.deltaTarget}
                                    </label>
                                    <div className="flex items-end h-7">
                                        <label className='  sm:text-sm mr-1'>{thisObject?.deltaTarget || '-'}</label>
                                        <label className="text-sm font-medium text-gray-700 ">{thisObject?.unit}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Company Goals */}
                        <div className='pb-4'>
                            <Tooltip content={text.goalTooltip} mobileClassName={tooltipClassname} breakpoint={tooltipBreakpoint} s />
                            <LabelWithSmarttips id={'organizationGoalsTips'} title={text.organizationalPrompt} tips={text.organizationTips} />
                            <div className={`${topBorder} flex space-x-4`}>
                                {/* Company Goal Input */}
                                <div>
                                    <label htmlFor="companyGoalsInput" className="block text-sm font-medium text-gray-700">
                                        {text.goalLabel}
                                    </label>
                                    <input
                                        id="companyGoalsInput"
                                        type="text"
                                        name="companyGoalsInput"
                                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 
                                                block  mr-1 
                                                sm:text-sm border-gray-300 rounded-md"
                                        placeholder={'...'}
                                        value={thisObject?.companyGoal}
                                        onChange={(e) => changeHandler(e, 'companyGoal')}
                                    />
                                </div>
                                {/* Percentage Input */}
                                <div>
                                    <label htmlFor="goalPercentageInput" className="block text-sm font-medium text-gray-700">
                                        {text.goalPercentageLabel}
                                    </label>
                                    <div className='flex align-baseline'>
                                        <input
                                            id="goalPercentageInput"
                                            type="number"
                                            min={0}
                                            max={100}
                                            name="goalPercentageInput"
                                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 w-20
                                                block  mr-1 text-center
                                                sm:text-sm border-gray-300 rounded-md"
                                            value={thisObject?.companyGoalPercent}
                                            onChange={(e) => changeHandler(e, 'companyGoalPercent')}
                                        />
                                        <span className='my-auto'>%</span></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {/* compact display for all stats in Overview */}
                {isOverview &&
                    <CompactMetricStatGrid
                        data={thisObject}
                        stats={stats}
                        companyGoal={companyGoal}
                        editActive={editActive}
                        changeDescriptionHandler={(e) => changeHandler(e, 'baselineDescription')}
                        changeUnitHandler={(e) => changeHandler(e, 'unit')}
                    />
                }
            </CollapsableDiv>
        </div>);
}

export default CoreMetricsForm;