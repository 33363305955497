import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import GuideForm from "components/GuideForm";
import Input from "components/InputElement";

import CommunityContext from "../context/CommunityContext"
import MultiSelectBoxMultiLevel from "components/MultiSelectBoxMultiLevel";

import { updateCheckitemState } from "utils/update-checkitem-state";
import { changeSelectedIdList as changeIdList } from "utils/change-selected-id-list";
import { filterValueChains } from 'pages/UserProfilePage/ProfileForm';

function CommunityForm({ handleValidation }) {
    const { t } = useTranslation(['community', 'user', 'common']);
    const text = {
        title: t('1-ps-title', "Title"),
        headline: t('1-ps-hl', "Your Community"),
        titlePlaceholder: t('1-ps-placeholder', "E.g. 'Marketing & Sales'"),
        industry: t('area0', 'Industries', { ns: 'community' }),
        segment: t('area1', 'Market Segments', { ns: 'community' }),
        valueChain: t('area2', 'Value Chain Segments', { ns: 'community' }),
    }
    const guideText = []
    const ctx = useContext(CommunityContext);
    const [guideActive, setGuideActive] = useState(false);
    const [industryList, setIndustryList] = useState([])
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);

    const [isTouched, setIsTouched] = useState({ title: false, statement: false });

    const [isValid, setIsValid] = useState({
        title: false
    });

    // updates internal segmentList with the checked objects
    useEffect(() => {
        if (ctx.segmentListData?.length > 0) {
            const newSegmentList = ctx.segmentListData.map(item => updateCheckitemState(item, ctx.data.segmentSelected || []));
            setSegmentList(newSegmentList);
            setIndustryList(newSegmentList.filter(item => item?.checked).map(item => item?.industry));
        }
    }, [ctx.segmentListData, ctx.data.segmentSelected]);

    // updates internal valueChainList with the checked objects
    // filter Value Chains either based on selected parents in Segments or previously selected Value Chains 
    useEffect(() => {
        let filteredList = filterValueChains(ctx.valueChainListData, ctx.data.valueChainSelected, industryList);
        const newValueList = filteredList.map(item => updateCheckitemState(item, ctx.data.valueChainSelected || []));
        setValueChainList(newValueList);
    }, [ctx.valueChainListData, ctx.data.valueChainSelected, industryList]);

    const handleBlur = (field) => (e) => {
        setIsTouched((prev) => ({
            ...prev,
            [field]: !e.target.value,
        }));
    };

    // updates the context data and the validation for the fields 
    const changeAndUpdateValidation = (event, fieldName) => {
        ctx.itemHandler(fieldName, event.target.value)
        setIsValid({ ...isValid, [fieldName]: event.target.value?.length > 0 })
    }

    // updates formData with new Checked id 
    const changeSegmentList = (item, checked) => {
        ctx.itemHandler('segmentSelected', changeIdList(ctx.data.segmentSelected, ctx.segmentListData, item, checked))
    };

    const changeValueChainList = (item, checked) => {
        ctx.itemHandler('valueChainSelected', changeIdList(ctx.data.valueChainSelected, ctx.valueChainListData, item, checked))
    };

    useEffect(() => {
        if (isValid.title) {
            handleValidation(true);
        } else {
            handleValidation(false);
        }
    }, [isValid]);

    const baseStyle = `shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md`
    const requiredInfo = <span title={t("This field is required", { ns: 'common' })} className="text-red-600 px-1">*</span>

    return (
        <div id="communityForm">
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4 2xl:w-1/3'} />
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={false}
                title={text.headline}>
                {/* Title Input */}
                <div>
                    <label htmlFor={text.title}
                        className="block text-base font-medium text-gray-700">
                        {text.title}
                        {requiredInfo}
                    </label>
                    <div className="mt-1">
                        <input
                            id="communityTitleInput"
                            type="text"
                            name={text.title}
                            className={`${isTouched.title && !ctx.data.title ? 'border-red-500' : ''} ${baseStyle}`}
                            placeholder={text.titlePlaceholder}
                            value={ctx.data.title}
                            onChange={(e) => changeAndUpdateValidation(e, 'title')}
                            onBlur={handleBlur("title")}
                            autoFocus
                        />
                    </div>
                </div>
                <Input
                    id={'interestsSegment'}
                    label={text.segment}
                    inputType={'other'}
                    labelClass={`pb-1 block`}
                    >
                    <MultiSelectBoxMultiLevel
                        list={segmentList}
                        updateList={changeSegmentList}
                        name={'interestsSegment'}
                    />
                </Input>
                <Input
                    id={'interestsValueChain'}
                    label={text.valueChain}
                    inputType={'other'}
                    labelClass={`pb-1 block`}
                    >
                    <MultiSelectBoxMultiLevel
                        list={valueChainList}
                        updateList={changeValueChainList}
                        name={'interestsValueChain'}
                    />
                </Input>
            </FormContainer>
        </div>
    );
}


export default CommunityForm;