import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "components/Card";
import CardGrid from "components/CardGrid";
import FormContainer from "components/FormContainer";
import Button from "components/Button";

import GuideForm from "components/GuideForm";

import Modal from 'components/Modal'

import BasicProfitabilityFramework from '../Images/svg/Folie7-BasicProfitabilityFramework.SVG';
import ExtendedProfitabilityFramework from '../Images/svg/ExtendedProfitabilityFramework.SVG';

// TODO translate
// TODO switch pictures for language switch
/**
 * First step for the guided structure
 */
function Step1WhyStructuringMatters() {
    const { t } = useTranslation();
    const text = {
        pageTitle: t('Why structuring a problem matters'),
        card1Intro: t(`Get the full picture:\n`),
        card1: t('Identify all potential factors that contribute to the resolution of your problem, as specified in your Key Results'),
        card2Intro: t(`Create a basis for prioritization:\n`),
        card2: t(`Focus on the factors that matter, and only on those, even if you did not do that in the past`),
        card3Intro: t(`Divide & Conquer:\n`),
        card3: t(`Create a basis to divide up the work: Structure your team around the factors you did prioritize`),
        basicProfitabilityFramework: t(`Basic Profitability Framework`)
    }
    const guideText = [
        {
            header: t('Why structure'),
            text: t(`• Get the full picture: Identify all potential factors* that contribute to tthe resolution of your problem, asspecified in your Key Results
            \n•	Create a basis for prioritization: Focus on the factors that matter, and only on those, even if you did not do that in the past
            \n•	Divide & Conquer: Create a basis to divide up the work**: Structure your team around the factors you did prioritize
            \n* A factor is something that impacts the desired outcome in your problem statement. You need to know and define these factors in order to solve your problem
            \n** This piece of work might be called  “epic”, “workstream”, or “sub-cluster” at your workplace. It is a piece of work behind which it typically makes sense to staff a team`)
        },
        {
            header: t('Literature'),
            text: t(`We highly recommend chapter 4 from the following book, which did greatly inspire this module: 
            \nConn, C. & McLean, D. (2018). Bulletproof Problem Solving. The one skill that changes everything. Wiley`)
        },
    ]
    const [guideActive, setGuideActive] = useState(false);

    const modalButton1 = <a id="a-DownloadBasic" href={BasicProfitabilityFramework} download='BasicProfitabilityFramework'>
        <Button>{t('Download')}</Button></a>

    const modalButton2 = <a id="a-DownloadExtended" href={ExtendedProfitabilityFramework} download='ExtendedProfitabilityFramework'>
        <Button>{t('Download')}</Button></a>

    return (
        <div id="step-whyStructuringMatters">
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
            />
            {/* MODALS */}
            <Modal id='BasicProfitabilityFramework' title={text.basicProfitabilityFramework} size='modal-xl'
                buttons={modalButton1}>
                <img src={BasicProfitabilityFramework} />
            </Modal>
            <Modal id='ExtendedProfitabilityFramework' title={text.basicProfitabilityFramework} size='modal-xl'
                buttons={modalButton2}>
                <img src={ExtendedProfitabilityFramework} />
            </Modal>
            <FormContainer
                openGuide={() => setGuideActive(!guideActive)}
                guide={true}
                width="lg:w-2/3 w-5/6" title={text.pageTitle}>
                {/* Info Cards */}
                <CardGrid gridCol="grid-cols-1 sm:grid-cols-3">
                    <Card title={text.card1Intro} text={text.card1} justify='justify-top' noClick={true}>
                    </Card>
                    <Card title={text.card2Intro} text={text.card2} justify='justify-top' noClick={true}>
                    </Card>
                    <Card title={text.card3Intro} text={text.card3} justify='justify-top' noClick={true}>
                    </Card>
                </CardGrid>
                {/* Cards with example images */}
                <CardGrid>
                    <button id="btn-toggleModal1" data-bs-toggle="modal" data-bs-target="#BasicProfitabilityFramework">
                        <Card title={'Basic Example'}>
                            <img src={BasicProfitabilityFramework} />
                        </Card>
                    </button>
                    <button id="btn-toggleModal2" data-bs-toggle="modal" data-bs-target="#ExtendedProfitabilityFramework">
                        <Card title={'Extended Example'}>
                            <img src={ExtendedProfitabilityFramework} />
                        </Card>
                    </button>
                </CardGrid>
            </FormContainer>
        </div>
    );
}

export default Step1WhyStructuringMatters;