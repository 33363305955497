import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import CommentOverlay from "components/Comment/CommentIndex";
import DatePicker from "components/DatePicker";
import FileCommentList from "components/FileCommentList";
import FormContainer from "components/FormContainer";
import GuideForm from "components/GuideForm";
import Input from "components/InputElement";
import LinkList from "components/LinkList";
import { ResizablePanelRight } from "components/Panels/ResizablePanelRight";
import Toolbar from "components/Toolbar";

import { alertActionTypes } from 'constants/alert.action.types';
import { getExecutiveSummary } from "actions/executive-summary.get.actions";
import { updateExecutiveSummary } from "actions/executive-summary.update.actions";
import { uploadFile } from 'actions/file.upload.actions';
import { deleteFile } from 'actions/file.delete.actions';
import MarkdownArea from "components/MarkdownArea";

function ExecutiveSummary({
    executiveSummary,
    profile,
    getExecutiveSummary,
    updateExecutiveSummary,
    uploadFile,
    deleteFile
}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { clusterId, problemId } = useParams();
    const [editModeActive, setEditModeActive] = useState(false)
    const [commentActive, setCommentActive] = useState(false)
    const [guideActive, setGuideActive] = useState(false)
    const { t } = useTranslation(['tabs', 'problemDefinition', 'common', 'executiveSummary']);
    const [hideCommentButton, setHideCommentButton] = useState(true)
    const text = {
        date: t('Date', { ns: 'common' }),
        responsible: t('Responsible'),
        summaryLabel: t('summary', 'Executive Summary'),
        summaryTip: t('summaryTip', 'Synthesize the situation, core observations and the resolution space of the problem'),
        documents: t('docs', 'Related Documents', { ns: 'common' }),
        linkList: t('1-ps-linkList', 'Related Links', { ns: 'problemDefinition' }),
    }
    const guideText = [
        { header: t('1-g1h', 'What is an Executive Summary?', { ns: 'executiveSummary' }), text: t('1-g1t', 'The Executive Summary is the synthesis of the entire problem solving process. It should contain the situation, core observations and, most importantly, the resolution space of the problem, as well as the core logic, arguments and proof points supporting the proposed resolution.', { ns: 'executiveSummary' }) },
        { header: t('1-g2h', 'When does a more top-down communication style work?', { ns: 'executiveSummary' }), text: t('1-g2t', 'The summary starts with the governing thought including the resolution space, the situation, and core observations. It is then followed by the logic of the case and supporting arguments and facts. This is a more managerial communication style, since you start with the resolution of the problem. Often an effective style, you should nonetheless assess whether it is suited for your audience', { ns: 'executiveSummary' }) },
        { header: t('1-g3h', 'When does a more bottom-up communication style work?', { ns: 'executiveSummary' }), text: t('1-g3t', 'The summary starts with the situation, core observations, and then leads to the resolution space, providing the logic and supporting arguments along the way: This is a communication style suited to audiences that first want to get a richer picture of the context before being confronted with your resolution of the problem. The style is often used in academics, or with audiences with very different personal or professional backgrounds', { ns: 'executiveSummary' }) },
    ]
    const [formData, setFormData] = useState({
        date: executiveSummary?.date,
        responsible: executiveSummary?.responsible,
        summary: executiveSummary?.summary,
        documents: executiveSummary?.documents || [],
        links: executiveSummary?.links || [],
    })

    useEffect(() => {
        if (editModeActive) {
            setCommentActive(false);
        } else {
            setGuideActive(false);
        }
    }, [editModeActive]);

    useEffect(() => {
        if (executiveSummary?.id && executiveSummary?.createdTs) {
            setHideCommentButton(false)
        } 
    }, []);

    // fetching executive summary from server initially
    useEffect(() => {
        if (problemId) {
            getExecutiveSummary(problemId)
        }
    }, [problemId]);

    // sync formData and executiveSummary from server
    useEffect(() => {
        setFormData({
            id: executiveSummary?.id || uuidv4(),
            createdTs: executiveSummary?.createdTs,
            date: executiveSummary?.date,
            responsible: executiveSummary?.responsible,
            summary: executiveSummary?.summary,
            documents: executiveSummary?.documents || [],
            links: executiveSummary?.links || [],
        })
    }, [executiveSummary]);

    // TODO: the same function is used in TopicDefinitionOverview, but it cannot be recycled somehow, bacause it does not await 
    // sync documents
    function syncDocuments(executiveSummaryId) {
        // local copy of the original data
        const data = { ...formData }

        const documentUploadList = data.documents?.filter(t => t.status === 'UPLOAD_PENDING');
        const documentDeleteList = data.documents?.filter(t => t.status === 'DELETE_PENDING');
        const documentKeepListOrig = data.documents?.filter(t => t.status !== 'UPLOAD_PENDING' && t.status !== 'DELETE_PENDING');

        const uploadPromises = documentUploadList?.map(doc =>
            uploadFile(executiveSummaryId, doc.filename, doc.file)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'UPLOADED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'UPLOAD_ERROR', response: error });
                })
        );

        const deletePromises = documentDeleteList?.map(doc =>
            deleteFile(executiveSummaryId, doc.filename)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'DELETED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'DELETE_ERROR', response: error });
                })
        );

        // Wait for all uploads to complete and return the updated document list
        Promise.allSettled(uploadPromises.concat(deletePromises)).then(results => {

            const fulfilled_upload = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'UPLOADED').map(result => result?.value);
            const fulfilled_delete = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'DELETED').map(result => result?.value);
            const rejected_upload = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'UPLOAD_ERROR').map(result => result?.reason);
            const rejected_delete = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'DELETE_ERROR').map(result => result?.reason);

            // excluding from keep list DELETED files
            const documentKeepList = documentKeepListOrig?.filter((d) => {
                return fulfilled_delete?.every((t) => {
                    return t.id !== d.id;
                });
            });

            const documentList = documentKeepList.concat(fulfilled_upload, rejected_upload, rejected_delete);

            data['documents'] = documentList
            // sync data and formData
            setFormData({ ...formData, documents: documentList })

            // sync data with backend
            updateExecutiveSummary(problemId, data).then(
                response => {
                    setEditModeActive(!editModeActive)
                    if (rejected_upload?.length > 0 || rejected_delete?.length > 0) {
                        dispatch({ type: alertActionTypes.ERROR, payload: 'some files could not be uploaded / deleted, please redo' })
                    }
                    history.push(`/clusters/${clusterId}/problems/${problemId}?id=summary`)

                })
        });
    }

    const handleSave = () => {
        updateExecutiveSummary(problemId, formData).then(
            // executive summary got ID
            response => {
                if (formData.documents?.length > 0) {
                    syncDocuments(formData.id)
                } else {
                    history.push(`/clusters/${clusterId}/problems/${problemId}?id=summary`)
                }
            }
        )

        setEditModeActive(false)

    }

    const onChange = (eventOrValue, name) => {
        if (eventOrValue?.target) {
            setFormData({ ...formData, [eventOrValue.target.name]: eventOrValue.target.value });
        } else {
            setFormData({ ...formData, [name]: eventOrValue });
        }
    };

    const labelClass = 'block mb-1 text-gray-600 text-base font-medium select-none'

    return (
        <>
            {executiveSummary?.id && executiveSummary?.createdTs && <ResizablePanelRight active={commentActive}>
                <CommentOverlay
                    active={commentActive}
                    profile={profile}
                    parentId={executiveSummary.id}
                    onClose={() => setCommentActive(false)}
                />
            </ResizablePanelRight>}
            <GuideForm
                guideContent={guideText}
                active={guideActive}
                onClose={() => setGuideActive(false)}
                width={'w-72 xl:w-1/4 2xl:w-1/4'}
            />
            <FormContainer id='executiveSummary' width={`${commentActive ? 'w-3/4' : 'w-full'}`} className='relative'
                guide={editModeActive}
                openGuide={() => setGuideActive(!guideActive)}
            >
                <div className="absolute right-0">
                    <Toolbar
                        adminView // TODO add 
                        commentActive={commentActive}
                        setCommentActive={setCommentActive}
                        hideComments={hideCommentButton}
                        disableComments={hideCommentButton}
                        toggleEditMode={() => setEditModeActive(!editModeActive)}
                        editModeActive={editModeActive}
                        handleSave={handleSave}
                    />
                </div>
                {/* Date and Responsible */}
                <div className="w-full md:w-[80%] flex flex-col space-y-8">
                    <div className="flex justify-between space-x-2">
                        <div>
                            <label className={labelClass}>{text.date}</label>
                            <DatePicker
                                value={formData.date}
                                onChange={(value) => onChange(value, 'date')}
                                viewOnly={!editModeActive} />

                        </div>
                        <div className='flex-grow'>
                            <Input
                                id={'responsible'}
                                label={text.responsible}
                                viewOnly={!editModeActive}
                                onChange={onChange}
                                value={formData.responsible}
                            />
                        </div>
                    </div>
                    {/* Summary */}
                    <Input
                        id={'summary'}
                        inputType='markdown'
                        label={text.summaryLabel}
                        tooltip={text.summaryTip}
                        viewOnly={!editModeActive}
                        onChange={(value) => onChange(value, 'summary')}
                        value={formData.summary}
                    />
                    {/* Attach documents */}
                    <div>
                        <label className={labelClass} htmlFor="Documents">
                            {text.documents}
                        </label>
                        <FileCommentList
                            editMode={editModeActive}
                            documentList={formData.documents}
                            setTempFiles={(data) => onChange(data, 'documents')}
                        />
                    </div>
                    {/* Linkslist */}
                    <div>
                        <label className={labelClass} htmlFor="Links">
                            {text.linkList}
                        </label>
                        <LinkList
                            editMode={editModeActive}
                            list={formData.links}
                            changeList={(data) => onChange(data, 'links')}
                        />
                    </div>
                </div>
            </FormContainer>
        </>
    );
}

ExecutiveSummary.propTypes = {
    getExecutiveSummary: PropTypes.func.isRequired,
    updateExecutiveSummary: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    executiveSummary: state.executiveSummaryReducer.executiveSummary,
    profile: state.profileReducer.profile
})

export default connect(mapStateToProps, {
    getExecutiveSummary,
    updateExecutiveSummary,
    uploadFile,
    deleteFile
})(ExecutiveSummary)