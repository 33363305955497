function buildPath(pathname, 
  currentCommunity, 
  clusters, 
  problems) {
    let pages = []
    if (pathname === '/home') {
      pages = []
    } else if (pathname === '/clusters') {
      pages = []
    } else if (pathname === '/profiles/me') {
      pages = [
        { name: 'me', href: '/profiles/me', current: true }
      ]
    } else if (pathname === '/communities/new') {
      pages = [
        { name: 'new', href: '/communities/new', current: true }
      ]
    } else if (/^\/profiles\/(?<profileId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/profiles\/(?<profileId>[a-zA-Z0-9-]+)$/)

  
      pages = [
        { name: match.groups.profileId, href: `/profiles/${match.groups.profileId}`, current: true }
      ]
    // } else if (/^\/community\/(?<communityId>[a-zA-Z0-9-]+)$/.test(pathname)) {
    //   let match = pathname.match(/^\/community\/(?<communityId>[a-zA-Z0-9-]+)$/)

    //   let communityName = match.groups.communityId
    //   if (currentCommunity?.title) {
    //     communityName = currentCommunity.title
    //   }

    //   pages = [
    //     { name: communityName, href: `/community/${match.groups.communityId}`, current: true }
    //   ]

    } else if (/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)$/)
  
      let clusterName = match.groups.clusterId
      if (clusters) {
        let cluster = null
        if (clusters.clusters) {
          cluster = clusters.clusters.find(x => x.id === match.groups.clusterId)
        } else if (clusters.cluster) {
          cluster = clusters.cluster
        }
        if (cluster?.title) {
          clusterName = cluster.title
        }
      }

      if (match.groups.clusterId === '00000000-0000-0000-0000-000000000000') {
        clusterName = 'SHARED'
      }
  
      pages = [
        { name: clusterName, href: `/clusters/${match.groups.clusterId}`, current: true }
      ]
    } else if (/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/topics\/new$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/topics\/new$/)
  
      let clusterName = match.groups.clusterId
      if (clusters) {
        let cluster = null
        if (clusters.clusters) {
          cluster = clusters.clusters.find(x => x.id === match.groups.clusterId)
        } else if (clusters.cluster) {
          cluster = clusters.cluster
        }
        if (cluster?.title) {
          clusterName = cluster.title
        }
      }

      if (match.groups.clusterId === '00000000-0000-0000-0000-000000000000') {
        clusterName = 'SHARED'
      }
  
      pages = [
        { name: clusterName, href: `/clusters/${match.groups.clusterId}`, current: false },
        { name: 'new', href: `/clusters/${match.groups.clusterId}/topics/new`, current: true }
      ]
    } else if (/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/problems\/new$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/problems\/new$/)
  
      let clusterName = match.groups.clusterId
      if (clusters) {
        let cluster = null
        if (clusters.clusters) {
          cluster = clusters.clusters.find(x => x.id === match.groups.clusterId)
        } else if (clusters.cluster) {
          cluster = clusters.cluster
        }
        if (cluster?.title) {
          clusterName = cluster.title
        }
      }

      if (match.groups.clusterId === '00000000-0000-0000-0000-000000000000') {
        clusterName = 'SHARED'
      }
  
      pages = [
        { name: clusterName, href: `/clusters/${match.groups.clusterId}`, current: false },
        { name: 'new', href: `/clusters/${match.groups.clusterId}/problems/new`, current: true }
      ]
    } else if (/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/problems\/new\?topicId=(?<problemId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/problems\/new\?topicId=(?<problemId>[a-zA-Z0-9-]+)$/)
  
      let clusterName = match.groups.clusterId
      if (clusters) {
        let cluster = null
        if (clusters.clusters) {
          cluster = clusters.clusters.find(x => x.id === match.groups.clusterId)
        } else if (clusters.cluster) {
          cluster = clusters.cluster
        }
        if (cluster?.title) {
          clusterName = cluster.title
        }
      }

      if (match.groups.clusterId === '00000000-0000-0000-0000-000000000000') {
        clusterName = 'SHARED'
      }
  
      pages = [
        { name: clusterName, href: `/clusters/${match.groups.clusterId}`, current: false },
        { name: 'new', href: `/clusters/${match.groups.clusterId}/problems/new?topicId=${match.groups.problemId}`, current: true }
      ]
    } else if (/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/topics\/(?<problemId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/topics\/(?<problemId>[a-zA-Z0-9-]+)$/)
  
      let clusterName = match.groups.clusterId
      if (clusters) {
        let cluster = null
        if (clusters.clusters) {
          cluster = clusters.clusters.find(x => x.id === match.groups.clusterId)
        } else if (clusters.cluster) {
          cluster = clusters.cluster
        }
        if (cluster?.title) {
          clusterName = cluster.title
        }
      }

      if (match.groups.clusterId === '00000000-0000-0000-0000-000000000000') {
        clusterName = 'SHARED'
      }
  
      let problemName = match.groups.problemId
      if (problems?.problem) {
        problemName = problems.problem.title
      }
  
      pages = [
        { name: clusterName, href: `/clusters/${match.groups.clusterId}`, current: false },
        { name: problemName, href: `/clusters/${match.groups.clusterId}/topics/${match.groups.problemId}`, current: true }
      ]
    } else if (/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/problems\/(?<problemId>[a-zA-Z0-9-]+)$/.test(pathname)) {
      let match = pathname.match(/^\/clusters\/(?<clusterId>[a-zA-Z0-9-]+)\/problems\/(?<problemId>[a-zA-Z0-9-]+)$/)
  
      let clusterName = match.groups.clusterId
      if (clusters) {
        let cluster = null
        if (clusters.clusters) {
          cluster = clusters.clusters.find(x => x.id === match.groups.clusterId)
        } else if (clusters.cluster) {
          cluster = clusters.cluster
        }
        if (cluster?.title) {
          clusterName = cluster.title
        }
      }

      if (match.groups.clusterId === '00000000-0000-0000-0000-000000000000') {
        clusterName = 'SHARED'
      }
  
      let problemName = match.groups.problemId
      if (problems?.problem) {
        problemName = problems.problem.title
      }
  
      pages = [
        { name: clusterName, href: `/clusters/${match.groups.clusterId}`, current: false },
        { name: problemName, href: `/clusters/${match.groups.clusterId}/problems/${match.groups.problemId}`, current: true }
      ]
    } else {
      pages = []
    }
    return pages
  }

  export default buildPath