import { useState, useEffect } from "react";
import EditableRow from "components/EditableRow";
import EditableTable from "components/EditableTable";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import TaskTable from "./TaskTable";
import { faChevronDown, faChevronUp, faClipboardList, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import ElementWithHoverinfo from "components/ElementWithHoverinfo";
import ResourceModal from "components/ResourceModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function MinutesTable({ editActive, updateTables, table, deleteActive, deleteTable, updateTask, deleteTask, addTask, hideHeader }) {
    const { t } = useTranslation(['minutes', 'problemDefinition', 'common'])
    const attachmentCount = (table.links?.length || 0) + (table.documents?.length || 0)
    const taskCount = table.tasks.length
    const [taskActive, setTaskActive] = useState(false)

    const tableHeader = [
        { name: 'body', text: t('mb', 'Meeting Body') },
        { name: 'date', text: t('dt', 'Date') },
        { name: 'type', text: t('ty', 'Type') },
        { name: 'item', text: t('it', 'Item') },
        { name: 'addTask', text: t('at', 'Tasks'), style: 'pr-12' },
        { name: 'addResources', text: t('res', 'Resources'), style: 'pr-12' },
        {},
    ]

    useEffect(() => {
        if(taskCount === 0){
            setTaskActive(false)
        }
    }, [taskCount]);

    const handleTaskClick = () => {
        // Adds initial task
        if (editActive && taskCount === 0) {
            addTask(table.id)
        }
        if (editActive || taskCount > 0) {
            setTaskActive(!taskActive)
        }
    }

    const taskIcon =
        <>
            <ElementWithHoverinfo
                icon={faClipboardList}
                active={taskCount > 0 || editActive}
                iconColor={taskCount > 0 ? 'text-primary-700' : 'text-gray-500'}
                number2={taskCount > 0 ? taskCount : null}
                onClick={handleTaskClick}
                tooltip={taskActive || !editActive ? null : t('addTask', 'Add Tasks')}
            />
            {taskCount > 0 && <FontAwesomeIcon className="pl-6 w-3 text-gray-500 cursor-pointer" icon={taskActive ? faChevronUp : faChevronDown}
                onClick={handleTaskClick} />}
        </>

    const resourceIcon =
        <ElementWithHoverinfo
            icon={faPaperclip}
            active={editActive || attachmentCount > 0}
            modal={"#modalMinutesResources" + table.id}
            iconColor={attachmentCount > 0 ? 'text-primary-700' : 'text-gray-500'}
            number2={attachmentCount > 0 ? attachmentCount : null}
            tooltip={editActive && t('addRes', 'Add Resources')}
        />

    // Style fixes for hidden header
    const ressourceStyle = `pl-4 pt-3 ${hideHeader ? 'w-[147px]' : 'w-[120px]'}`;
    const taskStyle = `pl-4 pt-3 ${hideHeader ? 'w-[127px]' : 'w-[120px]'}`;

    const rowData = [
        { id: 0, name: 'body', value: table.body, type: 'area', style: 'w-[300px]' },
        { id: 1, name: 'date', value: table.date, type: 'date', style: "w-[100px]" },
        { id: 2, name: 'type', value: table.type, type: 'selectMenu', itemList: ["INFORMATION", "DECISION"], style: "w-36" },
        { id: 3, name: 'item', value: table.item, type: 'area' },
        { id: 4, name: 'addTask', value: table.addTask, type: 'other', item: taskIcon, style: taskStyle },
        { id: 5, name: 'addResources', value: table.addResources, type: 'other', style: ressourceStyle, item: resourceIcon },
    ]

    const updateTableField = (field, value) => {
        console.log('updateTableField', field, value)
        const updatedTable = {
            ...table,
            [field]: value
        }
        updateTables(table.id, updatedTable)
    }

    return (
        <>
            <ResourceModal
                id={'modalMinutesResources' + table.id}
                title={'Resources for ' + table.body}
                editActive={editActive}
                linkList={table.links}
                documentList={table.documents}
                changeLinkList={(data) => updateTableField('links', data)}
                changeDocumentList={(data) => updateTableField('documents', data)}
            />
            <div id="minutesTable" className={`relative ${hideHeader && ' -mt-[17px]'}`}>
                <EditableTable
                    tableHeader={tableHeader}
                    rounded={'rounded-none'}
                    borderBottom={table.addTask || table.addResources ? 'border-b-0' : 'border-b'}
                    hideHeader={hideHeader}
                >
                    <EditableRow
                        id={'MinutesTable' + table.id}
                        key={table.id}
                        editActive={editActive}
                        rowData={rowData}
                        rowIndex={table.id}
                        changeHandler={(id, name, value) => updateTableField(name, value)}
                        noDelete={!deleteActive}
                        delete={() => deleteTable(table.id)}
                        translationFile='minutes'
                    />
                </EditableTable>
                {/* {deleteActive && editActive &&
                    <DeleteTableIcon deleteHandler={() => deleteTable(table.id)} />
                } */}
                {taskActive &&
                    <TaskTable
                        tableId={table.id}
                        tasks={table.tasks}
                        editActive={editActive}
                        updateTask={updateTask}
                        deleteTask={deleteTask}
                        addTask={addTask} />
                }
            </div>
        </>
    );
}

MinutesTable.propTypes = {
    editActive: PropTypes.bool.isRequired,
    updateTables: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
    deleteActive: PropTypes.bool.isRequired,
    deleteTable: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    addTask: PropTypes.func.isRequired,
}

export default MinutesTable;