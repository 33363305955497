import React from 'react'
import { useTranslation } from "react-i18next";
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/Button';
import CardGrid from 'components/CardGrid';

import IssueCard from './IssueCard';

function IssuesList() {
    const { t } = useTranslation();
    const text = {
        buttonText: t('3-li-btn', "New Issue"),
    }

    // TODO: replace dummy data with data from DB
    const issues = [
        {
            id: 'i1',
            name: "Heating Technology",
            state: 0,
            description: "Which heating technology and behavior changes do we need to implement to reduce heating emissions by at least 25%?",
            resolutionHypothesis:
                [
                    {
                        id: 'h1',
                        name: 'Hypo 1'
                    },
                    {
                        id: 'h1',
                        name: 'Hypo 2'
                    },
                ],
        },
        {
            id: 'i2',
            name: "Another Technology Here",
            state: 1,
            description: "Which heating technology and behavior changes do we need to implement to reduce heating emissions by at least 25%?",
            resolutionHypothesis:
                [
                    {
                        id: 'h1',
                        name: 'Hypo 1'
                    },
                ],
        },
        {
            id: 'i3',
            name: "Another",
            state: 2,
            description: "Which heating technology and behavior changes do we need to implement to reduce heating emissions by at least 25%?",
            resolutionHypothesis:
                [
                    {
                        id: 'h1',
                        name: 'Hypo 1'
                    },
                    {
                        id: 'h1',
                        name: 'Hypo 2'
                    },
                    {
                        id: 'h1',
                        name: 'Hypo 3'
                    },
                ],
        },
        {
            id: 'i4',
            name: "Another Technology",
            state: 3,
            description: "Which heating technology and behavior changes do we need to implement to reduce heating emissions by at least 25%?",
            resolutionHypothesis:
                [],
        },
        {
            id: 'i5',
            name: "Another Technology",
            state: 4,
            description: "Which heating technology and behavior changes do we need to implement to reduce heating emissions by at least 25%?",
            resolutionHypothesis:
                [],
        },
    ]

    const { clusterId, problemId } = useParams();

    return (
        <div id='issueList'
            className='mt-4 space-y-4'>
            <Link to={`/clusters/${clusterId}/problems/${problemId}/issues/new`}>
                <Button className="mt-2">
                    <FontAwesomeIcon icon={faPlus} className={'my-auto mr-2'} />
                    {text.buttonText}
                </Button>
            </Link>
            {/* Issues Grid */}
            <CardGrid className={'items-start'}>
                {issues.map((issue) => (
                    <IssueCard issue={issue} />
                ))}
            </CardGrid>
        </div>


    )
}

export default IssuesList