const clusterActionTypes = {
    LIST_CLUSTERS_REQUEST: 'LIST_CLUSTERS_REQUEST',
    LIST_CLUSTERS_SUCCESS: 'LIST_CLUSTERS_SUCCESS',
    LIST_CLUSTERS_FAILURE: 'LIST_CLUSTERS_FAILURE',

    GET_PROJECT_REQUEST: 'GET_PROJECT_REQUEST',
    GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
    GET_PROJECT_FAILURE: 'GET_PROJECT_FAILURE',

    CREATE_PROJECT_REQUEST: 'CREATE_PROJECT_REQUEST',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    UPDATE_PROJECT_REQUEST: 'UPDATE_PROJECT_REQUEST',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'

    DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS', // TODO: not used, replaced with 'ALERT_SUCCESS'
    DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE', // TODO: not used, replaced with 'ALERT_FAILURE'
};

export { clusterActionTypes };