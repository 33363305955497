const forenDict = {
    // New Topic
    'fo-pageTitleCluster': 'Themencluster',
    'fo-pageDescCluster': 'Themencluster werden durch Ihren Organisator vordefiniert und dienen der Strukturierung.',
    'fo-nt': 'Neues Cluster',
    'fo-cluster-t': 'Cluster Titel',
    'fo-cluster-desc': 'Beschreibung',
    'fo-start': 'Erstellen Sie ein erstes Cluster um zu beginnen',
    'fo-createNew': 'Neues Cluster erstellen',
    // Neues Thema
    'fo-newTheme': 'Neues Thema erstellen',
    'fo-getStarted': 'Erstellen Sie ein erstes Thema um zu beginnen',
    // Userbereich
    'fo-userTopics': 'Themen erstellt von',
    'fo-relatedTopics': 'Passende Themen',
    // Cluster
    'fo-noClusters': 'Keine Themencluster wurden bis jetzt angelegt.'
}

export default forenDict;