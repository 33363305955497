import { useTranslation } from "react-i18next";

import Modal from 'components/Modal';
import CardGrid from 'components/CardGrid';
import Card from 'components/Card';

function ModalOrganisationSwitch({organisationId, organisations, setOrganisationId}) {
  const { t } = useTranslation(['common']);

  const onOrganisationClick = (desiredId) => {
    if (desiredId !== organisationId) {
      const desiredOrganisation = organisations.find(x => x.id === desiredId)
      if (desiredOrganisation) {
        sessionStorage.setItem('organisation', JSON.stringify(desiredOrganisation))
        setOrganisationId(desiredOrganisation.id)
      }
    }
  }

  return (
      <Modal
      id='companiesModal'
      title={t('Change your platform')}
      size={'modal-lg modal-dialog-centered'}
      backdrop
    >
      {/* Placeholder for Logos which would need to be loaded into the cardGrid with urls for the respective companies */}
      <CardGrid className='text-center'>
        {organisations && organisations.map((item) => (
          item.id === organisationId ?
            <Card key={item.id} onClick={() => onOrganisationClick(item.id)} active>{item.title}</Card>
            :
            <Card key={item.id} onClick={() => onOrganisationClick(item.id)}>{item.title}</Card>
        ))
        }
      </CardGrid>
    </Modal>
  );
}

export { ModalOrganisationSwitch };