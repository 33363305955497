import { useState } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "components/Tooltip";
import LabelWithSmarttips from "components/LabelWithSmarttips";
import FormContainer from "components/FormContainer";
import GuideForm from "components/GuideForm";

import StakeholdersTable from "./StakeholdersTable";

// TODO delete guide related items 

function StakeholdersForm() {
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        headline: t('1-sh-hl', "Stakeholders"),
        tooltip: t('1-sh-tooltip', `Defining your stakeholders is critical to ensure you involve essential people early in the process turning them into co-authors`),
        label: t('1-sh-prompt', "Define relevant internal and external participants"),
        stakeholderTips: [
            {
                title: t('1-sh-1t', "Balance"),
                description: t('1-sh-1d', "Is your view balanced?")
            },
            {
                title: t('1-sh-2t', "Iceberg"),
                description: t('1-sh-2d', "Did you identify points that are ‘below the surface’?")
            },
            {
                title: t('1-sh-3t', "Pragmatic completeness"),
                description: t('1-sh-3d', "Did you identify all relevant stakeholder groups? Consider both impact and affectedness")
            },
            {
                title: t('1-sh-4t', "Confidentiality"),
                description: t('1-sh-4d', "Make sure to insert highly confidential information in the fields labelled “CONFIDENTIAL - CORE TEAM ONLY”")
            },
        ],
    }
    const [guideActive, setGuideActive] = useState(false);
    const guideText = [
        { 
            header: t('1-sh-g1h', 'What is a stakeholder?'), 
            text: t('1-sh-g1t', 'A group or a person who has direct or indirect influence on the outcome of your cluster, and/or has an interest in it') 
        },
        { 
            header: t('1-sh-g2h', 'What is the influence of a stakeholder?'), 
            text: t('1-sh-g2t', 'The influence of a stakeholder describes his ability to make a difference in the outcome of the cluster') 
        },
        { 
            header: t('1-sh-g3h', 'What determines the degree of affectedness or interest of a stakeholder?'), 
            text: t('1-sh-g3t', 'Affectedness/Interest is typically determined by the impact the cluster has on the stakeholder and/or the degree to which she will be held accountable for the outcome') 
        },
        { 
            header: t('1-sh-g4h', 'How to describe the needs of a stakeholder?'), 
            text: t('1-sh-g4t', 'What the stakeholder stated as his needs and/or what can be reasonably assumed due to his interest. Provide insights about the reasons for the needs if possible') 
        },
    ]

    return (
        <div>
            <GuideForm guideContent={guideText}
                active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false} />
            <FormContainer title={text.headline}
                width="lg:w-full w-full"
                guideWidth="w-3/5 md:w-2/3 xl:w-3/4 2xl:w-5/6"
                openGuide={() => setGuideActive(!guideActive)}
                guide
                onGuideActive={guideActive}>
                <div>
                    <Tooltip content={text.tooltip} breakpoint="none" />
                    <LabelWithSmarttips id="stakeholdeTips" title={text.label} tips={text.stakeholderTips} />
                    <StakeholdersTable editActive={true} />
                </div>
            </FormContainer>
        </div>
    );
}

export default StakeholdersForm;