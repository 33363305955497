import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from "components/LabelWithSmarttips";

import TopicContext from "pages/CreateTopicPage/context/TopicContext";
import KeyResultTable from "./KeyResultTable";
import { AddTableButton } from "components/Elements/AddTableButton";

function KeyResults({ isOverview, editActive }) {
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        tooltip: t("1-kr-tooltip", "All of your key results need to be achieved in order for the problem to be considered solved sufficiently. Key results should, but need not contain quantitative elements"),
        title: t("1-kr-prompt", "When is the problem considered to be solved?"),
        tipsList: [
            {
                title: t("1-kr-1t", "Specific"),
                description: t("1-kr-1d", "What do you want to achieve? E.g. “increase profit”, “reduce response time”. Be careful not to confuse objective and constraint.")
            },
            {
                title: t("1-kr-2t", "Measurable"),
                description: t("1-kr-2d", "Provide a quantitative value. E.g. “by 50%”, “down to 5 sec”")
            },
            {
                title: t("1-kr-3t", "Realistic"),
                description: t("1-kr-3d", "Make sure the key results are reachable")
            },
            {
                title: t("1-kr-4t", "Time bound"),
                description: t("1-kr-4d", "By when should it be reached? E.g. “by 2030”, “within next 2 years”")
            },
            {
                title: t("1-kr-5t", "Open"),
                description: t("1-kr-5d", "Avoid providing any solution. Leave room for creativity and innovations")
            },
            {
                title: t("1-kr-6t", "Contributing"),
                description: t("1-kr-6d", "Ensure a measure direct or indirect contribution to the overall goals of your organization")
            },
        ],
        dodPlaceholder: "...",
        addKey: t("1_kr_addkey", "+ Add another Key Result"),
    }
    const ctx = useContext(TopicContext)

    // TODO get state from context #86c06yvww
    // const [keyResultTable, setKeyResultTable] = useState([0]);

    const keyResultsTablesData = ctx.data.keyResults;

    // create one inital table if there is none 
    useEffect(() => {
        if (keyResultsTablesData.length === 0) {
            addTable()
        }
    }, []);

    const addTable = () => {
        //even though Key result is technically a table, the object can be handled like a new row instead of a 'new table' 
        ctx.newRowHandler('keyResults')
    };

    return (
        <div>
            {!isOverview &&
                <>
                    <Tooltip content={text.tooltip} />
                    <LabelWithSmarttips id="dodTips" title={text.title} tips={text.tipsList} />
                </>}
            {/* <KeyResultTable /> */}
            {keyResultsTablesData && keyResultsTablesData.map((item, index) => (
                <div key={index} className="mb-4">
                    <KeyResultTable
                        index={index}
                        data={keyResultsTablesData[index]}
                        key={item.id}
                        isOverview={isOverview}
                        editActive={editActive}
                    />
                </div>
            ))}
            {editActive && <div>
                <AddTableButton addTable={addTable} text={text.addKey} />
            </div>}
        </div>
    );
}

export default KeyResults;