import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseItem } from 'utils/session-storage';
import sortList from 'utils/sort-list';
// import EmptyState from './EmptyState';
import ClustersList from './ClustersList';
import GridHeader from 'components/GridHeader/indexGridHeader';

function ClustersListView({
  community,
  clusters
}) {

  const { t } = useTranslation(['foren', 'infobanners']);
  const clusterInfo = {
    title: t('fo-pageTitleCluster', 'Content Clusters'),
    desc: t('fo-pageDescCluster', 'Content Clusters are predefined by your convener to provide a high-level structure.')
  }
  const bannerText = t("banner-clusters",
    "Select a content cluster in order to define a problem, an open question or a topic request and to provide feedback on the existing ones.\nRate the clusters up or down depending on how relevant and/or useful they are to you.", { ns: 'infobanners' })

  const [clustersList, setClustersList] = useState([]);
  const [communityData, setCommunityData] = useState({ users: [] });
  const [sortType, setSortType] = useState('oldest');

  useEffect(() => {
    if (parseItem('community')?.id && clusters?.length > 0) {
      // hotfix: changing organisation
      let clustersMatchedCurrentCommunity = clusters.filter(cluster => cluster.communityId === parseItem('community').id)

      if (clustersMatchedCurrentCommunity.length > 0) {
        // Initial Results are all problems
        const savedSortType = localStorage.getItem('clustersSortOrder');
        let sortedList = []
        if (savedSortType) {
          sortedList = sortList(clustersMatchedCurrentCommunity, savedSortType)
        } else {
          sortedList = sortList(clustersMatchedCurrentCommunity, sortType)
        }
        setClustersList(sortedList)
      }
    } else {
      setClustersList([])
    }
  }, [clusters]);

  useEffect(() => {
    if (community) {
      setCommunityData(
        {
          ...community
        }
      )
    }
  }, [community])

  const handleSortChange = (newSortType) => {
    setSortType(newSortType)
    const sortedList = sortList(clustersList, newSortType)
    setClustersList(sortedList)
    localStorage.setItem('clustersSortOrder', newSortType);
  }

  const handleUpdateList = (updatedList) => {
    setClustersList(updatedList)
  }

  const resetList = () => {
    const filteredclusters = clusters.filter(cluster => cluster.communityId === parseItem('community').id);
    const sortedList = sortList(filteredclusters, sortType);
    setClustersList(sortedList);
  }

  const filterProps = {
    originalList: clustersList,
    searchFields: ["title", "desc", "role"],
    storeFields: ["title", "desc", "problems", "members", "role", "userVotes", "problems", "contributorsCount"],
    searchBoost: { title: 2, desc: 1 },
    updateList: handleUpdateList,
    resetList: resetList,
    handleSortChange: handleSortChange,
    currentSortType: sortType,

  }

  return (
    <GridHeader
      id='clusterListView'
      bannerText={bannerText}
      topicInfo={clusterInfo}
      teamUserList={communityData?.users}
      filterProps={filterProps}
    >
      {parseItem('community') && <ClustersList clusters={clustersList} />}
    </GridHeader>
  );
}

ClustersListView.propTypes = {
  community: PropTypes.object.isRequired,
  clusters: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
  community: state.communityReducer.currentCommunity,
  clusters: state.clusterReducer.clusters
});

export default connect(mapStateToProps, null)(ClustersListView);