import { instance as axios } from 'ajax';
import { batch } from 'react-redux';

import Cookies from 'js-cookie';
import { alertActions } from './alert.actions';
import { loadingActions } from './loading.actions';
import { problemActionTypes as types } from 'constants/problem.action.types';
import { toCamelCase } from 'utils/snake-converter'

export function getProblemById(id) {

    

    return dispatch => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': Cookies.get('csrf_access_token')
            }
        };

        batch(() => {
            dispatch(request());
            dispatch(loadingActions.start());
        });

        return axios
            .get(`/api/v1/problems/${id}`, config)
            .then(
                response => {
                    batch(() => {
                        dispatch(success(response.data));
                        dispatch(loadingActions.finish());
                    });

                    return response;
                },
                error => {
                    batch(() => {
                        dispatch(failure(error));
                        dispatch(loadingActions.finish());
                    });
                    
                    if (error.response) {
                        // Request made and server responded
                        dispatch(alertActions.error(error.response.data));
                        //console.log(error.response.data);
                        //console.log(error.response.status);
                        //console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(alertActions.error(error.request));
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        dispatch(alertActions.error(error.message));
                    }
                }
            );
    };

    function request(payload) { return { type: types.GET_PROBLEM_REQUEST, payload: payload } }
    function success(response) { return { type: types.GET_PROBLEM_SUCCESS, payload: toCamelCase(response) } }
    function failure(error) { return { type: types.GET_PROBLEM_FAILURE, payload: error } }

};