import { React, } from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';

// MarkdownArea is a component that displays a markdown editor and/or a markdown preview
// It uses the @uiw/react-md-editor package to display the editor and the preview
function MarkdownArea({ value, onChange, editMode, preview = 'live', hideToolbar, height, border = 'border', }) {

    return (
        <div style={{ height: height }}>
            {editMode ? (
                <MDEditor
                    value={value}
                    onChange={onChange}
                    preview={preview}
                    source={value}
                    hideToolbar={hideToolbar}
                    commandsFilter={(cmd) => cmd && /(comment|code|image)/.test(cmd.name) ? true : cmd}
                />
            ) : (
                <MDEditor.Markdown
                    source={value}
                    className={`wmde-markdown ${border} rounded-md p-2`} />
            )}

            <style>
                {`.wmde-markdown {
                    background-color: #fff;
                    height: 100%;
                }
                .wmde-markdown ul,
                .wmde-markdown ol {
                    all: revert;
                }
                .wmde-markdown p {
                    white-space: pre-wrap !important;
                }
                    `
                }
            </style>
        </div>
    );
}

MarkdownArea.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    editMode: PropTypes.bool,
};

export default MarkdownArea;