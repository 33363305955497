import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from "react-i18next";
import { PlusIcon } from '@heroicons/react/outline';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';

import HeaderText from 'components/HeaderText';

import CardGrid from 'components/CardGrid';
import ModalNewEntity from 'components/Modal';
import EmptyCardFrame from 'components/EmptyCardFrame';
import EmptyState from 'components/EmptyStateCreateNew';

import { createCluster } from 'actions/cluster.create.actions';
import { updateClusterById } from 'actions/cluster.update.actions';
import { updateVoteById } from 'actions/voting.update.actions';
import { listUserClusters } from 'actions/clusters.list.actions';
import { parseItem } from 'utils/session-storage';

import ClusterCard from './ClusterCard';
import LinkList from 'components/LinkList';

function ClustersList({
    profile,
    clusters,
    createCluster,
    updateClusterById,
    updateVoteById,
    listUserClusters
}) {
    const { t } = useTranslation(['foren']);
    const text = {
        button: t('fo-nt', 'New Cluster'),
        labelTitle: t('fo-cluster-t', 'Cluster Title'),
        labelDesc: t('fo-cluster-desc', 'Description'),
        labelLinks: t('', 'Links'),
        headlineEmpty: "No Cluster",
        sublineEmpty: t('fo-start', "Get started by creating a new cluster."),
        createNewHover: t('fo-createNew', 'Create a new Cluster'),
        pageTitle: t('fo-pageTitleCluster', 'Content Clusters'),
        pageDesc: t('fo-pageDescCluster', 'Content Clusters are predefined by your convener to provide a high-level structure.')
    }

    const [organisationRole, setOrganisationRole] = useState(null);
    const [communityRole, setCommunityRole] = useState(null);
    const [canCreateCluster, setCanCreateCluster] = useState(false);
    const [editingcluster, setEditingcluster] = useState(null);
    const [urlInputActive, setUrlInputActive] = useState(false);
    const [modalValid, setModalValid] = useState(null);
    const [clustersSorted, setclustersSorted] = useState([]);
    const linkListRef = useRef();

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        links: []
    });

    const { title, description, links } = formData;

    // validation for modal save button 
    useEffect(() => {
        if (editingcluster) {
            setModalValid(editingcluster.title?.length)
        } else {
            setModalValid(title.length > 0)
        }
    }, [formData, editingcluster])

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const changeLinkList = (list) => {
        setFormData({ ...formData, links: list })
        if (editingcluster) {
            setEditingcluster({ ...editingcluster, links: list })
        }
    }

    const handleUrlActive = (state) => {
        setUrlInputActive(state)
    }

    const onEditChange = (e) =>
        setEditingcluster({ ...editingcluster, [e.target.name]: e.target.value })

    const createNewcluster = (e) => {
        e.preventDefault()
        createCluster(title, description, links).then(() => {
            listUserClusters()
        })
    }

    const handleEdit = (cluster) => {
        setEditingcluster(cluster);
    };

    const handleEditcluster = (e) => {
        e.preventDefault();
        updateClusterById(
            editingcluster.id,
            {
                title: editingcluster.title,
                desc: editingcluster.desc,
                links: editingcluster.links
            }
        ).then(() => {
            listUserClusters()
        });
        setEditingcluster(null);
    };

    const abortEditcluster = () => {
        setEditingcluster(false)
        linkListRef.current.resetList();
    }

    const abortNewcluster = () => {
        linkListRef.current.resetList();
    }

    // organisation role
    useEffect(() => {        
        if (parseItem('organisation')?.role) {
            setOrganisationRole(parseItem('organisation').role)
          }
    }, [sessionStorage.getItem('organisation')]);

    // community role
    useEffect(() => {
        if (parseItem('community')?.role) {
            setCommunityRole(parseItem('community').role);
        } 
    
    }, [sessionStorage.getItem('community')]);


    // flag whether user can create a cluster
    useEffect(() => {
        setCanCreateCluster(false)
        if (communityRole === 'OWNER' || communityRole === 'ADMIN') {
            setCanCreateCluster(true)
        }
        
        if (organisationRole === 'OWNER' || organisationRole === 'ADMIN') {
            setCanCreateCluster(true)
        }
    }, [communityRole, organisationRole]);

    useEffect(() => {
        setclustersSorted(clusters)
    }, [clusters]);

    const upVote = (cluster) => {
        const userVote = cluster.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote + 1 <= 1 ) {
            updateVoteById(cluster.id, userVote + 1).then(() => {
                listUserClusters()
            })
        }
    }

    const downVote = (cluster) => {
        const userVote = cluster.userVotes.find(obj => obj.userId === profile.userId)?.vote || 0
        if ( userVote - 1 >= -1 ) {
            updateVoteById(cluster.id, userVote - 1).then(() => {
                listUserClusters()
            })
        }
    }

    return (
        <div id='ClustersListPage'>
            <ModalNewEntity
                id='modalNewcluster'
                title={editingcluster ? 'Edit cluster' : text.button}
                onSave={editingcluster ? handleEditcluster : createNewcluster}
                saveDisabled={urlInputActive || !modalValid}
                onClose={editingcluster ? abortEditcluster : abortNewcluster}>
                <label className='block text-base font-medium text-gray-700'>
                    {text.labelTitle}
                </label>
                <input
                    id='title'
                    type='text'
                    name='title'
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={editingcluster ? editingcluster.title : title}
                    onChange={editingcluster ? onEditChange : onChange}
                    required
                />
                <label className='block text-base font-medium text-gray-700 mt-4'>
                    {text.labelDesc}
                </label>
                <TextareaAutosize
                    id='description'
                    type="text"
                    name={editingcluster ? 'desc' : 'description'}
                    minRows="3"
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    value={editingcluster ? editingcluster.desc : description}
                    onChange={editingcluster ? onEditChange : onChange}
                />
                {/* Adding Links to clusters */}
                <label className='block text-base font-medium text-gray-700 mt-4'>
                    {text.labelLinks}
                </label>
                <LinkList
                    id='clusterLinks'
                    ref={linkListRef}
                    changeList={changeLinkList}
                    list={editingcluster && editingcluster.links}
                    editMode
                    urlInputActive={handleUrlActive}
                />
            </ModalNewEntity>
            <div className='space-y-4'>
                {/* cluster Grid */}
                {clustersSorted?.length > 0 ? (
                    <CardGrid className={'items-start'}>
                        {clustersSorted.map((cluster, index) => (
                            <ClusterCard
                                key={index}
                                cluster={cluster}
                                userVotes={cluster.userVotes}
                                count={cluster.userVotes.reduce((acc, obj) => (acc + obj.vote), 0)}
                                showVoting={cluster.id === '00000000-0000-0000-0000-000000000000' ? false : true}
                                upVote={() => upVote(cluster)}
                                downVote={() => downVote(cluster)}
                                upVoteClicked={cluster.userVotes?.find(obj => obj.userId === profile.userId)?.vote === 1}
                                downVoteClicked={cluster.userVotes?.find(obj => obj.userId === profile.userId)?.vote === -1}
                                handleEdit={() => handleEdit(cluster)}
                                databstoggle="modal"
                                databstarget="#modalNewcluster"
                            />
                        ))}
                        {/* Card for creating new clusters */}
                        {canCreateCluster &&
                            <EmptyCardFrame
                                id='newClusterCard'
                                className={'transition-all h-full items-center justify-center cursor-pointer border-dashed group hover:border-primary-700 hover:bg-primary-50'}
                                databstoggle="modal"
                                databstarget="#modalNewcluster">
                                <p className='inline-flex items-center transition-all scale-100 text-gray-500 group-hover:scale-110 group-hover:text-black my-9'>
                                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                    <span>{text.button}</span></p>
                            </EmptyCardFrame>
                        }
                    </CardGrid>
                ) :
                    <>
                        {canCreateCluster ?
                            <EmptyState
                                icon={faFolderPlus}
                                hoverText={text.createNewHover}
                                descriptionText={text.sublineEmpty}
                                databstoggle='modal'
                                databstarget='#modalNewcluster'
                            />
                            :
                            <p className='text-gray-500'>{t('fo-noClusters', 'No Clusters have been added to this Community')}</p>}
                    </>
                }
            </div>
        </div >
    );
}

ClustersList.propTypes = {
    profile: PropTypes.object.isRequired,
    createCluster: PropTypes.func.isRequired,
    updateClusterById: PropTypes.func.isRequired,
    updateVoteById: PropTypes.func.isRequired,
    listUserClusters: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { createCluster, updateVoteById, updateClusterById, listUserClusters })(ClustersList);