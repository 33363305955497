import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from "react-i18next";
import EditableStatTile from './EditableStatTile';
import { XIcon } from "@heroicons/react/outline";
import ProptTypes from 'prop-types';

function CompactMetricStatGrid({ data, stats, companyGoal,
    changeDescriptionHandler, changeUnitHandler, editActive, deleteHandler, deleteActive,
    headerLabel }) {
    const { t } = useTranslation();

    return (
        <div id='compactMetricStatGrid' className="rounded-lg bg-white shadow border">
            <div className="flex w-full justify-between">
                {/* Description, 'Denomination and Date' */}
                {editActive ?
                    <TextareaAutosize
                        onChange={changeDescriptionHandler}
                        className='w-1/2 resize-none ml-1 p-1 border-0 focus:border-1 focus:bg-primary-100'
                        value={data?.baselineDescription || ''}
                        placeholder={t('1-pm-label1', "Denomination and Date")}>
                    </TextareaAutosize>
                    :
                    <label className="pl-2 text-gray-500">{data?.baselineDescription || '...'}</label>
                }
                <h3 className="text-base font-small mt-1 text-gray-400 mr-2">{headerLabel}</h3>
                {deleteActive && <XIcon className='w-5 h-5 mr-2 cursor-pointer m-auto group-hover:text-primary-600 text-gray-500 transform group-hover:scale-125'
                    onClick={deleteHandler} />}
            </div>
            <div className={`mt-1 grid grid-cols-2  
                2xl:grid-cols-4 
                overflow-hidden divide-x divide-gray-200`}>
                {/* Stats*/}
                {stats.map((item) => (
                    <EditableStatTile
                        key={item.name}
                        editActive={editActive}
                        name={item.name}
                        type="number"
                        value={item.value}
                        unit={data?.unit}
                        percentage={item.percentage}
                        readOnly={item.readOnly}
                        onChange={item.onChange}
                        onUnitChange={changeUnitHandler} />
                ))}
                {/* Company Goal */}
                {companyGoal &&
                    <div className='flex'>
                        {/* Buffer Span */}
                        <span className='w-1 bg-gray-200'></span>
                        <EditableStatTile
                            editActive={editActive}
                            name={companyGoal.name}
                            type="text"
                            value={companyGoal.value}
                            percentage={companyGoal.percentage}
                            badgeColor={"bg-blue-100 text-blue-800"}
                            onChange={companyGoal.onChange}
                            percentageEditable={editActive}
                            onPercentageChange={companyGoal.onPercentageChange} />
                    </div>
                }
            </div>
        </div>
    );
}

// functions are only required when editActive is true
const requiredIfEditing = (props, propName, componentName) =>
    props.editActive && typeof props[propName] !== 'function'
        ? new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. It is required when \`editActive\` is true.`)
        : null;

CompactMetricStatGrid.propTypes = {
    data: ProptTypes.object.isRequired,
    stats: ProptTypes.array.isRequired,
    companyGoal: ProptTypes.object.isRequired,
    changeDescriptionHandler: requiredIfEditing,
    changeUnitHandler: requiredIfEditing,
    editActive: ProptTypes.bool.isRequired,
    deleteHandler: ProptTypes.func,
    deleteActive: ProptTypes.bool,
    headerLabel: ProptTypes.string
}

export default CompactMetricStatGrid;