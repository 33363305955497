import { meetingMinutesActionTypes as types } from 'constants/meeting-minutes.action.types';
  
const initialState = (window.Cypress && window.initialState && window.initialState.meetingMeenutesReducer) || {};

export function meetingMinutesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_MEETING_MINUTES_REQUEST:
    case types.UPDATE_MEETING_MINUTES_REQUEST:
      return {
          ...state,
          ...payload
      };
    case types.GET_MEETING_MINUTES_SUCCESS:
    case types.UPDATE_MEETING_MINUTES_SUCCESS:
      return {
        ...state,
        meetingMinutes: payload
    };
    case types.GET_MEETING_MINUTES_FAILURE:
    case types.UPDATE_MEETING_MINUTES_FAILURE:
    default:
      return state;
  }
}
  