import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import PageWrapper from 'components/PageWrapper';
import StepFormWrapper from 'components/StepFormWrapper';

import CommunityContext from './context/CommunityContext';
import CommunityForm from './Steps/CommunityForm';
import { alertActionTypes } from 'constants/alert.action.types';
import CommunityOverview from './Overview/CommunityOverview';
import { createCommunity } from 'actions/community.create.actions';
import { listUserCommunities } from 'actions/communities.list.actions';

function NewCommunityForm({ createCommunity, listUserCommunities, alert }) {
    const history = useHistory();
    const { t } = useTranslation(['problemDefinition']);
    const ctx = useContext(CommunityContext);

    // clearing all Alerts that was before
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: alertActionTypes.CLEAR })
    }, [dispatch]);

    const stepList = [
        { name: t('1-ps-hl', 'Community', { ns: 'community' }), href: '#', status: 'current' },
        { name: t('1-prev', 'Preview'), href: '#', status: 'upcoming' },
    ]

    let [currentStep, setCurrentStep] = useState(1);

    // TODO: this kills all
    // if (alert.type === 'success') {
    //     history.push('/home') // TODO: /clusters/{id}/topics/{id}
    // }

    const onSave = () => {
        // e.preventDefault()
        createCommunity(null, ctx.data).then(()=>{
            listUserCommunities()
        })
        history.push('/home')
    };

    const [isValid, setIsvalid] = useState(false);

    const handleValidation = (state) => {
        setIsvalid(state);
    }

    return (
        <>
            <PageWrapper id={'newCommunityForm'}>
                <StepFormWrapper
                    stepList={stepList}
                    currentStep={setCurrentStep}
                    onSave={onSave}
                    isValid={isValid}
                    inValidMessage={t('required-hoverInfo1Community', 'Please fill in all required fields.')}
                    buttonGroupId={'buttonGroup-communityForm'}
                    backLink={`/home`}>
                    <div className='h-full overflow-x-visible'>
                        <div className='py-4 max-w-7xl'>
                            {/* Container for Forms */}
                            <div id='NewCommunityFormSectionContainer' className='py-4 pl-1'>
                                {currentStep === 1 &&
                                    <CommunityForm handleValidation={handleValidation} />}
                                {currentStep === 2 &&
                                    <CommunityOverview
                                        editOnly={true}
                                        handleValidation={handleValidation} />}
                            </div>
                        </div>
                    </div>
                </StepFormWrapper>
            </PageWrapper>
        </>
    );
}

NewCommunityForm.propTypes = {
    createCommunity: PropTypes.func.isRequired,
    listUserCommunities: PropTypes.func.isRequired,
    alert: PropTypes.object,
};

const mapStateToProps = state => ({
    alert: state.alertReducer
});

export default connect(mapStateToProps, { createCommunity, listUserCommunities })(NewCommunityForm);
