import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// Props: itemList (Simple Array), selected, style (for width)
function SelectMenu({ selected, onChange, translationFile, editActive, ...props }) {
    const { t } = useTranslation(translationFile)
    const width = (props.style || 'w-40')
    const itemList = props.itemList;
    // const [selected, setSelected] = useState(props.selected || itemList[0])

    // Displays and translates text in correct format without disturbing the values for the database
    const translateAndFormat = (text) => {
        if (!text) return "";
        let translatedText = t(text)
        translatedText = translatedText?.charAt(0).toUpperCase() + translatedText?.slice(1).toLowerCase();
        return translatedText
    };

    return (
        <> {editActive ?
            <Listbox value={selected} onChange={onChange}>
                {({ open }) => (
                    <>
                        <div className="my-1 relative">
                            <Listbox.Button
                                className={`${width} bg-white relative  border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default 
                    focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}>
                                <span className="block truncate">{translateAndFormat(selected)}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {itemList?.map((item) => (
                                        <Listbox.Option
                                            key={item}
                                            className={({ active, selected }) =>
                                                classNames(
                                                    selected ? 'bg-primary-200 text-white' : '',
                                                    active ? 'bg-primary-600 text-white font-bold ' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3'
                                                )
                                            }
                                            value={item}
                                        >
                                            <span className="block truncate">
                                                {translateAndFormat(item)}
                                            </span>
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            :
            <label className={`${width} mt-[13px] ml-[13px] sm:text-sm  block`}>{translateAndFormat(selected)}</label>
        }

        </>
    )
}

SelectMenu.propTypes = {
    itemList: PropTypes.array.isRequired,
    selected: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.string,
    translationFile: PropTypes.string,
};

export default SelectMenu;
