import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next"
import DisplayTagList from "components/DisplayTagList";
import UserClusters from "./UserClusters";
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import SimpleGroupHover from 'components/SimpleGroupHover';
import { flatArrayForSelected } from 'utils/flat-array-for-selected';


function DisplayProfile({ 
    userData, 
    problemMatches, 
    ownProblems,
    owner,
    isAdmin,
    memberOfOrganisations,
    memberOfCommunities,
    segmentList,
    valueChainList }) {
    const { t } = useTranslation(['user', 'foren', 'common']);
    const text = {
        interestTags: t('interests', 'Other Interests'),
        editTitle: t('Edit Profile'),
        segment: t('area1', 'Areas of interest by market segment'),
        valueChain: t('area2', 'Area of interest by value chain'),
        private: t('Private Information'),
        memberOfOrganisations: t('member-of-organisations', 'Member of Organisations'),
        memberOfCommunities: t('member-of-communities', 'Member of Communities')
    }

    const {
        firstName,
        lastName,
        email,
        organisation,
        position,
        interestTags,
        segmentSelected,
        valueChainSelected
    } = userData;

    return (
        <>
            <div>
                <div id="profilMainInformation">
                    <p className="text-lg font-bold">{firstName} {lastName}</p>
                    {(owner || isAdmin) && <p className="text-sm text-gray-700">{email}
                        <SimpleGroupHover position={'-top-1 left-10'} text={text.private} className='whitespace-nowrap'>
                            <FontAwesomeIcon className='ml-2 text-gray-400' icon={faEyeSlash} />
                        </SimpleGroupHover></p>}
                    <p className="mt-4">{position}</p>
                    <p>{organisation}</p>
                </div>
                {/* interests and tags */}
                <div className="mt-4 space-y-4">
                    <div>
                        <label>{text.memberOfOrganisations}:</label>
                        <DisplayTagList
                            list={memberOfOrganisations}
                            bgColor={'bg-orange-100'}
                            borderColor={'border-orange-200'} />
                    </div>
                    <div>
                        <label>{text.memberOfCommunities}:</label>
                        <DisplayTagList
                            list={memberOfCommunities}
                            bgColor={'bg-yellow-100'}
                            borderColor={'border-yellow-200'} />
                    </div>

                    {segmentSelected?.length >= 0 &&
                        <div>
                            <label>{text.segment}</label>
                            <DisplayTagList
                                list={flatArrayForSelected(segmentList, segmentSelected)}
                                bgColor={'bg-sky-100'}
                                borderColor={'border-sky-200'} />
                        </div>
                    }

                    {valueChainSelected?.length >= 0 &&
                        <div>
                            <label>{text.valueChain}</label>
                            <DisplayTagList
                                list={flatArrayForSelected(valueChainList, valueChainSelected)}
                                bgColor={'bg-green-100'}
                                borderColor={'border-green-200'} />
                        </div>
                    }

                    {interestTags?.length >= 0 &&
                        <div>
                            <label>{text.interestTags}</label>
                            <DisplayTagList list={interestTags}
                                bgColor={'bg-indigo-100'}
                                borderColor={'border-indigo-200'} />
                        </div>
                    }
                </div>
            </div>
            <UserClusters
                userName={firstName + " " + lastName}
                problemMatches={problemMatches}
                ownProblems={ownProblems}
                owner={owner}
            />
        </>
    );
}

export default DisplayProfile;